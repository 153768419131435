import moment from "moment";
import has from "lodash/has";
import get from 'lodash/get'
import last from "lodash/last";
import filter from "lodash/filter";

import { combineReducers } from "redux";

import * as actions from "./actions";

const SECOND_IN_MS = 1000

const deviceStatus = (state = {}, action) => {
    switch (action.type) {
        case actions.WS_MESSAGE_RECEIVED:
            return mergeDeviceStatus(
                state,
                action.deviceId,
                extractStatusFromRawMessage(action.msg)
            );
        case actions.UPDATE_DEVICE_STATUS:
            return mergeDeviceStatus(
                state,
                action.deviceId,
                liveness(state, action.deviceId)
            );
        default:
            return state;
    }
};

const mergeDeviceStatus = (state, deviceId, newStatus) => {
    const newState = {};
    newState[deviceId.toString()] = {
        ...state[deviceId.toString()],
        ...newStatus
    };
    return {
        ...state,
        ...newState
    };
};

const extractStatusFromRawMessage = msg => {
    const status = { lastPing: moment(), isOnline: true };

    if (has(msg, "hb")) {
        // Heartbeat message
        status.ipAddrs = msg.hb.ipAddrs;
        status.port = msg.hb.port;
        status.oaVersion = msg.oaVersion;
        status.settings = msg.hb.settings;
        status.octolapse = msg.hb.octolapse;
    }

    ["job", "state", "progress", "stream", "temps", "hlsAvailable"].forEach(key => {
        if (has(msg, key)) {
            status[key] = msg[key];
        }
    });

    if (has(msg, "temps") && last(msg.temps)) {
        status.temps = last(msg.temps);
    }

    if (get(msg, 'state.text', '')) {
        status.isReady = get(msg, 'state.text', '') == 'Operational';
    }

    if (has(msg, "type")) {
        status.lastEventName = msg.type;
    }

    return status;
};

const liveness = (state, deviceId) => {
    const lastPing = get(state[deviceId.toString()], 'lastPing', null)
    return {isOnline: lastPing != null && moment().diff(lastPing) < 90 * SECOND_IN_MS }
}

const devices = (state = [], action) => {
    switch (action.type) {
        case actions.DEVICE_LIST_FETCH_SUCCESS:
            return action.devices;
        default:
            return state;
    }
}

const currentUser = (state = null, action) => {
    switch (action.type) {
        case actions.USER_FETCH_SUCCESS:
            const { user } = action;
            if (user && user.Subscriptions) {
                const proSubs = filter(user.Subscriptions, sub => sub.plan.startsWith('pro'))
                if (proSubs.length > 0) {
                    user.currentSubscription = proSubs[0].plan
                }
                user.hasPro = user.Subscriptions.length > 0 || user.isProTrialActive
            }
            return user;
        default:
            return state;
    }
}

// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
const loading = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  };
};

export default combineReducers({
    deviceStatus,
    devices,
    loading,
    currentUser,
});
