import React, { Component } from "react";
import { connect } from "react-redux";
import FontIcon from "material-ui/FontIcon";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";

import GuestAccessDialog from "../common/GuestAccessDialog";

import { colors } from "material-ui/styles";

class CardMenuComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteDlgOpen: false,
            sharingDlgOpen: false
        };
    }

    deleteMenuTapped() {
        this.setState({
            deleteDlgOpen: true
        });
    }

    cancelDelete() {
        this.setState({
            deleteDlgOpen: false
        });
    }

    confirmDelete() {
        this.props.delegate.printJobDeleted();
        this.setState({
            deleteDlgOpen: false
        });
    }

    sharingMenuTapped() {
        this.setState({
            sharingDlgOpen: true
        });
    }

    sharingDismissed() {
        this.setState({
            sharingDlgOpen: false
        });
    }

    render() {
        const deleteDlgBtns = [
            <FlatButton
                label="No"
                primary={true}
                onClick={() => this.cancelDelete()}
            />,
            <FlatButton
                label="Yes"
                primary={false}
                onClick={() => this.confirmDelete()}
            />
        ];
        return (
            <div>
                <IconMenu
                    className="card-menu"
                    iconButtonElement={
                        <IconButton>
                            <FontIcon className="icon-mui-more_vert" />
                        </IconButton>
                    }
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    targetOrigin={{ horizontal: "right", vertical: "top" }}
                >

                    <MenuItem
                        primaryText="Download"
                        onClick={this.props.delegate.downloadTimelapse}
                        leftIcon={<FontIcon
                            className="icon-mui-download3"
                        />}
                    />
                    <MenuItem
                        primaryText='Sharing'
                        onClick={() => this.sharingMenuTapped()}
                        leftIcon={<FontIcon className="icon-mui-share3" />}
                    />
                    <MenuItem
                        style={{ color: colors.red500 }}
                        primaryText="Delete"
                        onClick={() => this.deleteMenuTapped()}
                        leftIcon={<FontIcon className="icon-mui-delete" style={{ color: colors.red500 }} />}
                    />
                </IconMenu>
                <Dialog
                    title="Are you sure?"
                    actions={deleteDlgBtns}
                    modal={true}
                    open={Boolean(this.state.deleteDlgOpen)}
                >
                    Do you really want to delete time-lapse for{" "}
                    {this.props.printJob.gcodeName} ?
                </Dialog>
                {this.state.sharingDlgOpen ? (
                    <GuestAccessDialog
                        endpoint={`/api/printjobs/${
                            this.props.printJob.id
                            }/guests/`}
                        publicPathPrefix={`${window.location.protocol}//${
                            window.location.host
                            }/pub/guesttl/`}
                        title="Time-lapse Sharing"
                        onDismiss={this.sharingDismissed.bind(this)}
                    />
                ) : null}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.currentUser,
    };
};
export default connect(
    mapStateToProps,
    null,
)(CardMenuComponent);
