export default {
    cardBody: {
        marginBottom: '8px',
    },
    dialogTitle: {
        textAlign: 'center',
        fontWeight: '700',
    },
    section: {
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding: '8px',
        margin: '8px',
    },
    temperature: {
        fontWeight: '700',
    },
    deviceControls: {
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    xyControls: {
        flex: '0 1 70%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    xControls: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    zControls: {
        display: 'flex',
        flex: '0 5 30%',
        flexFlow: 'column nowrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    controlBtn: {
        height: '38px',
        width: '38px',
    },
    miniIcon: {
        fontSize: '16px',
        marginLeft: '3px',
    },
    orientationWarning: {
        margin: '12px 0px 0px',
        padding: '6px',
        background: 'orange',
    },
    octoprintMenu: {
        paddingLeft: '54px',
    },
    octoprintMenuIcon: {
        position: 'absolute',
        top: '0px',
        margin: '12px',
        left: '4px',
        height: "24px",
        width: "24px",
    },
}
