import React, {
    Component
} from 'react';
import update from 'immutability-helper';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import Toggle from 'material-ui/Toggle';
import CopyToClipboard from 'react-copy-to-clipboard';
import { zIndex } from 'material-ui/styles';

export default class GuestAccess extends Component {
    constructor(props) {
        super(props);
        this.state = { linkCopied: false }
    }

    componentDidMount() {
        this.updateGuestAccesses();
    }

    updateGuestAccesses() {
        fetch(this.props.endpoint, {
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(result => result.json())
        .then(guestAccesses => {
            this.setState({
                guestAccesses,
            });
        });
    }

    sharingToggled(event, isInputChecked) {
        if (isInputChecked) {
            fetch(this.props.endpoint, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                this.updateGuestAccesses();
            });
        } else {
            if (!this.state.guestAccesses || this.state.guestAccesses.length < 1) {
                return ;
            }

            fetch(this.props.endpoint + this.state.guestAccesses[0].token, {
                credentials: 'include',
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                this.updateGuestAccesses();
            });
        }
    }

    shareLink(url) {
        if (url) {
            return (
                <div className="clipboard-container">
                  <input type="text" className="clipboard-target" value={url} readOnly={true} />
                  <div className="clipboard-btn-group">
                      <CopyToClipboard
                          text={url}
                          onCopy={() => {
                              this.setState(
                                  update(this.state, {
                                      linkCopied: {
                                          $set: true
                                      }
                                  }));
                          }}>
                          <button aria-label="Copy to clipboard" className="clipboard-btn" type="button">
                              <span className="icon-mui-paste" color="#757575" />
                              &nbsp;Copy
                          </button>
                      </CopyToClipboard>
                  </div>
                </div>
            );
        }

        return '';
    }

    render() {
        const sharingDlgBtns = [
            <FlatButton
                label="OK"
                primary={true}
                keyboardFocused={true}
                onClick={() => this.props.onDismiss()}
            />
        ];
        const sharedUrl = this.state.guestAccesses && this.state.guestAccesses.length > 0 ?
             this.props.publicPathPrefix + this.state.guestAccesses[0].token : null;
        return (
                <Dialog
                    title={this.props.title}
                    actions={sharingDlgBtns}
                    modal={false}
                    open={true}
                    onRequestClose={() => this.props.onDismiss()}
                    style={{zIndex: 3000}}
                >
                    <div className='settings-section'>
                        <Toggle
                            labelPosition='right'
                            onToggle={this.sharingToggled.bind(this)}
                            label={sharedUrl !== null ? "Anyone with this link can view" : "Not sharing"}
                            toggled={sharedUrl !== null}
                         />
                         { sharedUrl && <div className='hint-sharing-off'>Toggle again to turn off sharing</div> }
                         {this.shareLink(sharedUrl)}
                         {this.state.linkCopied ? <p>Link copied to your system clipboard.</p> : null}
                    </div>
                </Dialog>
        );
    }
}
