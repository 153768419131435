import React, { Fragment } from "react";
import get from 'lodash/get'
import filter from 'lodash/filter'
import find from 'lodash/find'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

import withMobileDialog from '@material-ui/core/withMobileDialog';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PrintIcon from '@material-ui/icons/Print';
import Slide from '@material-ui/core/Slide';
import grey from '@material-ui/core/colors/grey';

import {
    subscribeToDevice,
    unsubscribeFromDevice,
    sendMessageToDevice,
} from "../../actions";
import styles from "./styles";
import commonStyles from '../common/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class PrintDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceToSendGCodeFile: null,
            printCmdSent: false,
            gcodeDownloadStarted: false,
            printStarted: false,
        };
    }
    componentDidMount() {
        this.props.devices.forEach(device => {
            this.props.subscribeToDevice(device.id);
        })
    }

    componentWillUnmount() {
        this.props.devices.forEach(device => {
            this.props.unsubscribeFromDevice(device.id);
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {};
        if (prevState.deviceToSendGCodeFile == null && nextProps.devices.length == 1) {
            const deviceToSendGCodeFile = nextProps.devices[0]
            Object.assign(newState, { deviceToSendGCodeFile });
        }

        if (prevState.deviceToSendGCodeFile) {
            const status = get(nextProps.deviceStatus, prevState.deviceToSendGCodeFile.id, {});
            if (prevState.printCmdSent && !prevState.gcodeDownloadStarted  && status.lastEventName == 'DownloadStarted') {
                Object.assign(newState, { gcodeDownloadStarted: true });
            }
            if (prevState.gcodeDownloadStarted && !status.isReady) {
                Object.assign(newState, { printStarted: true });
                setTimeout(() => window.location = '/app/devices', 5000);
            }
        }

        return newState;
    }

    deviceSelectionChanged = (e) => {
        const dev = find(this.props.devices, ['id', e.target.value])
        this.setState({ deviceToSendGCodeFile: dev });
    }

    printCancelled = () => {
        this.props.onClose();
    }

    printConfirmed = () => {
        this.setState({printCmdSent: true});
        let {url, id, filename, safeFilename} = this.props.fileToPrint;
        const cmd = {
            job: {
                start: {id, url, filename: safeFilename ? safeFilename : filename}
            }
        };
        this.props.sendMessageToDevice(
            JSON.stringify({ cmd }),
            this.state.deviceToSendGCodeFile.id
        );
    }

    deviceStatusBox() {
        const device = this.state.deviceToSendGCodeFile;
        const status = this.props.deviceStatus[device.id];

        if (this.state.printStarted) {
            return (
                <Fragment>
                <PrintIcon color="primary" />
                <p>Print has started.</p>
                <p>Redirecting to <Link
                                to="/app/devices"
                                style={commonStyles.link}
                            >Dashboard</Link> in a few seconds...</p>
                </Fragment>
            )
        }
        if (this.state.gcodeDownloadStarted) {
            return (
                <Fragment>
                <CircularProgress />
                <p>Sending gcode file to OctoPrint...</p>
                </Fragment>
            )
        }
        if (this.state.printCmdSent) {
            return (
                <Fragment>
                <CircularProgress />
                <p>Contacting OctopPrint...</p>
                </Fragment>
            )
        }
        if (!get(status, 'state.text')) {
            return (
                <Fragment>
                <CircularProgress />
                <p>Fetching status</p>
                </Fragment>
            );
        }
        if (status.isReady) {
            return (
                <Fragment>
                    <CheckCircleIcon color='primary' fontSize="large" />
                    <p>Ready to print!</p>

                    <Button variant="contained" onClick={this.printConfirmed} color="primary">
                        Print it!
                    </Button>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                <RemoveCircleIcon color='secondary' fontSize="large" />
                <p>Not ready!</p>
                </Fragment>
            )
        }
    }

    render() {
        return (
            <Dialog
                open={true}
                onClose={this.printCancelled}
                fullScreen={this.props.fullScreen}
                fullWidth
                PaperProps={{style: {minHeight: "560px"}}}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Print GCode file "{get(this.props, 'fileToPrint.filename')}"</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" alignItems="center" spacing={5} id="alert-dialog-description">
                        {this.props.devices.length > 1 && (
                            <Fragment>
                                <Grid item>
                                    <p>Which printer do you want to print it on?</p>
                                    <FormControl>
                                        <InputLabel htmlFor="printer-select">Printer</InputLabel>
                                        <Select
                                            value={this.state.deviceToSendGCodeFile == null ? -1 : this.state.deviceToSendGCodeFile.id}
                                            onChange={this.deviceSelectionChanged}
                                            inputProps={{
                                                name: 'age',
                                                id: 'printer-select',
                                            }}
                                            disabled={this.state.printCmdSent}
                                        >
                                            <MenuItem value={-1}>
                                                <em>Not Selected</em>
                                            </MenuItem>
                                            {this.props.devices.map((device, i) => <MenuItem key={i} value={device.id}>{device.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        )}
                        {this.state.deviceToSendGCodeFile && (
                            <Grid item style={{...styles.printDialogPrinterStatus, background: grey[200], textAlign: 'center'}}>
                            <p style={styles.printDialogPrinterName}>{this.state.deviceToSendGCodeFile.name}</p>
                            {this.deviceStatusBox()}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.printCancelled} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    const { deviceStatus, devices, currentUser } = state;

    return {
        currentUser,
        devices: filter(devices, ['subscription', 'pro']),
        deviceStatus,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        subscribeToDevice: deviceId => {
            dispatch(subscribeToDevice(deviceId));
        },
        unsubscribeFromDevice: deviceId => {
            dispatch(unsubscribeFromDevice(deviceId));
        },
        sendMessageToDevice: (msg, deviceId) => {
            dispatch(sendMessageToDevice(msg, deviceId));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(PrintDialog));
