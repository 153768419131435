import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import has from "lodash/has";
import get from "lodash/get";
import update from "immutability-helper";
import ifvisible from "ifvisible.js";
import { Card, CardMedia, CardTitle } from "material-ui/Card";

import VideoPlayer from "../common/VideoPlayer";
import { subscribeToDeviceAsGuest, unsubscribeFromDevice } from "../../actions";

import CameraStub from "../../global-assets/images/camera.png";
import commonStyles from "../common/styles";
import styles from "./styles";

class GuestDeviceCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.currentImgUrl = CameraStub;
        this.imgLoading = false;
        this.lastImageLoaded = Date.now();
    }

    componentDidMount() {
        this.timer = setInterval(() => this.forceUpdate(), 10000);

        this.props.subscribeToDeviceAsGuest(this.props.sharedToken, this.props.device.id);

        ifvisible.on("focus", () => {
            this.props.subscribeToDeviceAsGuest(this.props.sharedToken, this.props.device.id);
        });

        ifvisible.on("blur", () => {
            this.props.unsubscribeFromDevice(this.props.device.id);
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.props.unsubscribeFromDevice(this.props.device.id);
    }

    onImgDoneLoading() {
        this.imgLoading = false;
        this.lastImageLoaded = Date.now();
    }

    isOffline() {
        return moment().diff(this.props.deviceStatus.lastPing) > 120 * 1000;
    }

    webcamFeed() {
        if (get(this.props, "deviceStatus.hlsAvailable", false)) {
            const videoJsOptions = {
                // autoplay: true,
                muted: true,
                controls: true,
                fluid: true,
                sources: [{
                    src: `/video/pub/${this.props.sharedToken}/livestream.m3u8`,
                    type: 'application/x-mpegURL',
                }],
            }

            return (<div>
                <VideoPlayer {...videoJsOptions} />
                </div>
            )
        }

        let outterClassName = "";
        let innerClassName = "";
        if (this.currentImgUrl !== CameraStub) {
            if (this.props.device.rotate90) {
                outterClassName += " webcam_rotated";
            }
            if (this.props.device.rotate90N) {
                outterClassName += " webcam_rotated_n";
            }
            if (this.props.device.flipV) {
                innerClassName += " flipV";
            }
            if (this.props.device.flipH) {
                innerClassName += " flipH";
            }
        }

        if (!this.imgLoading && has(this.props, "deviceStatus.stream.imgUrl")) {
            this.imgLoading =
                    this.props.deviceStatus.stream.imgUrl != this.currentImgUrl;
                this.currentImgUrl = this.props.deviceStatus.stream.imgUrl;
        }

        return (
            <div id="webcam_rotator" className={outterClassName}>
                <div className="webcam_fixed_ratio ratio43">
                    <div className="webcam_fixed_ratio_inner">
                        <img
                            id="webcam_image"
                            src={this.currentImgUrl}
                            className={innerClassName}
                            onLoad={this.onImgDoneLoading.bind(this)}
                            onError={this.onImgDoneLoading.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="card-container">
                <div className="row center-md center-lg">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="device-card">
                                {this.webcamFeed()}
                        </div>
                    </div>
                </div>
                <div style={styles.footnote} className="row center-xs center-sm center-md center-lg">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div>
                            This camera feed is shared using{" "}
                            <a
                                style={commonStyles.link}
                                href="https://plugins.octoprint.org/plugins/anywhere/"
                            >
                                OctoPrint Anywhere
                            </a>.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const deviceStatus = state.deviceStatus[ownProps.device.id] || {
        lastPing: moment.unix(0)
    };

    return {
        deviceStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        subscribeToDeviceAsGuest: (sharedToken, deviceId) => {
            dispatch(subscribeToDeviceAsGuest(sharedToken, deviceId));
        },
        unsubscribeFromDevice: deviceId => {
            dispatch(unsubscribeFromDevice(deviceId));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestDeviceCardComponent);
