import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import cyan from '@material-ui/core/colors/cyan';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Help from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import { fetchUser } from "../../actions";
import styles from './styles';
import ProPlanImg from '../../global-assets/images/proplan.png';
import ProPlusPlanImg from '../../global-assets/images/proplusplan.png';

const theme = createMuiTheme({
    palette: {
        primary: cyan,
    },
});

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: '0.9rem',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const Toggle = withStyles({
    switchBase: {
        color: cyan[500],
        '&$checked': {
            color: cyan[500],
        },
        '&$checked + $track': {
            backgroundColor: cyan[500],
        },
    },
    checked: {},
    track: { backgroundColor: cyan[500] },
})(Switch);


class SubscriptionComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            yearly: null,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.yearly == null && nextProps.currentUser) {
            const subscriptions = nextProps.currentUser.Subscriptions;
            if (subscriptions.length > 0) {
                return { yearly: filter(subscriptions, sub => sub.plan.includes('yearly')).length > 0 };
            } else {
                return { yearly: true }
            }
        } else {
            return null;
        }
    }

    componentDidMount() {
        this.cbInstance = window.Chargebee.init({
            site: window.config.CHARGEBEE_SITE,
        })
        this.cbInstance.setPortalSession(() => {
            return fetch('/api/cb/generate_portal_session', {
                credentials: 'include',
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(result => result.json())
        })
        gtag('event', 'view', {
            'event_category': this.props.location.pathname,
        });
    }

    subscribe = planId => () => {
        this.state.yearly && (planId += '_yearly')
        this.cbInstance.openCheckout({
            hostedPage: () => {
                return fetch('/api/cb/generate_checkout_url', {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        plan_id: planId,
                        quantity: 1,
                    }),
                }).then(result => result.json())
            },
        })
        gtag('event', 'click', {
            'event_category': 'upgrade',
            'event_label': planId,
        });
    }

    portal = () => {
        this.cbInstance.createChargebeePortal().open({
            loaded(hostedPageId) {
                console.log(hostedPageId)
            },
        })
    }

    planDurationChanged = event => {
        this.setState({ yearly: event.target.checked })
    }

    eligibleForTrial() {
        return this.props.currentUser && !this.props.currentUser.proTrialStartedAt && !this.props.currentUser.hasPro
    }

    currentPlanIsPro() {
        return includes(['pro', 'pro_yearly'], get(this.props, ['currentUser', 'currentSubscription']))
    }

    currentPlanIsProPlus() {
        return includes(['proplus', 'proplus_yearly'], get(this.props, ['currentUser', 'currentSubscription']))
    }

    currentPlanIsFree() {
        return get(this.props, ['currentUser', 'currentSubscription']) == null
    }

    proPlanActions() {
        if (this.currentPlanIsPro()) {
            if (get(this.props, 'currentUser.Subscriptions[0].externalId') == 'pre-subscription') {
                return (
                    <Button fullWidth variant="contained" color="primary" onClick={this.subscribe('pro')}>
                        Renew
                    </Button>
                )
            }
            return (
                <Button fullWidth variant="outlined" color="primary" onClick={this.portal}>
                    Change Or Cancel
                </Button>
            )
        }
        if (this.currentPlanIsProPlus()) {
            return (
                <Button fullWidth variant="contained" color="primary" onClick={this.subscribe('pro')}>
                    Downgrade
            </Button>
            )
        }

        if (this.eligibleForTrial()) {
            return (
                <div style={{width: '100%'}}>
                <Button fullWidth variant="contained" color="primary" onClick={this.subscribe('pro')} style={{marginTop: '12px'}}>
                    Upgrade
                </Button>
                </div>
            )
        }
        return (
            <Button fullWidth variant="contained" color="primary" onClick={this.subscribe('pro')}>
                Upgrade
            </Button>
        )
    }

    proPlusPlanActions() {
        if (this.currentPlanIsProPlus()) {
            return (
                <Button fullWidth variant="outlined" color="primary" onClick={this.portal}>
                    Change Or Cancel
                </Button>
            )
        }

        return (
            <Button fullWidth variant="contained" color="primary" onClick={this.subscribe('proplus')}>
                Upgrade
        </Button>
        )
    }

    subscription() {
        return (
            <ThemeProvider theme={theme}>
                <Container maxWidth="lg" component="main">
                    <Divider />
                    <br />
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Monthly</Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Toggle
                                        checked={Boolean(this.state.yearly)}
                                        onChange={this.planDurationChanged}
                                        color="primary"
                                    />
                                }
                                label="Yearly (SAVE 15%)"
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" component="main">
                    <Grid container spacing={5} alignItems="stretch">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card style={styles.pricingCard}>
                                <CardHeader
                                    title={<img style={{ width: '56px', paddingTop: '4px' }} src={ProPlanImg} />}
                                    subheader={this.currentPlanIsPro() ? 'Your current plan' : null}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={null}
                                    style={styles.pricingHeader}
                                />
                                <CardContent root={styles.pricingContent}>
                                    <div style={styles.price}>
                                    {this.state.yearly ? (
                                    <React.Fragment>
                                        <div>
                                            <Typography variant="h3" color="textPrimary" display="inline">
                                                $4
                                            </Typography>
                                            <Typography variant="h4" color="textPrimary" display="inline">
                                                .25
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary" display="inline">
                                                /month
                                            </Typography>
                                        </div>
                                        <Typography variant="h6" color="textSecondary" display="inline">
                                                Billed annually at $51/year
                                        </Typography>
                                    </React.Fragment>
                                    ) : (
                                        <div>
                                            <Typography variant="h3" color="textPrimary" display="inline">
                                                $5
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary" display="inline">
                                                /month
                                            </Typography>
                                        </div>
                                    )}
                                    </div>
                                    <ul style={styles.featureUl}>
                                        <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                            Connect up to 2 printers
                                </Typography>
                                        <HtmlTooltip
                                            placement="top"
                                            disableFocusListener
                                            disableTouchListener
                                            title={
                                                <React.Fragment>
                                                    You need to have Raspberry Pi with Pi Camera (the cameras with CSi interface such as <a href="https://www.raspberrypi.org/products/camera-module-v2/">this one</a>) in order to have 25 FPS video streaming. For details please check <a href="https://www.getanywhere.io/assets/oa10.html#picamera">1.0 release page.</a>
                                                </React.Fragment>
                                            }
                                        >
                                            <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                                Webcam streaming up to 25 FPS <span style={{ fontWeight: 'bolder', color: 'red' }}>(Pi Camera required)</span> <Help fontSize="small" />
                                            </Typography>
                                        </HtmlTooltip>
                                        <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                            Time-lapses stored up to 12 months
                                </Typography>
                                        <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                            Webcam and time-lapses sharing
                                </Typography>
                                        <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                            GCode remote upload and printing
                                </Typography>
                                    </ul>
                                </CardContent>
                                {this.props.currentUser && <CardActions style={styles.pricingAction}>
                                    {this.proPlanActions()}
                                </CardActions>}
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Card style={styles.pricingCard}>
                                <CardHeader
                                    title={<img style={{ width: '73px', paddingTop: '4px' }} src={ProPlusPlanImg} />}
                                    subheader={this.currentPlanIsProPlus() ? 'Your current plan' : null}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={null}
                                    style={styles.pricingHeader}
                                />
                                <CardContent style={styles.pricingContent}>
                                    <div style={styles.price}>
                                    {this.state.yearly ? (
                                    <React.Fragment>
                                        <div>
                                            <Typography variant="h3" color="textPrimary" display="inline">
                                                $8
                                            </Typography>
                                            <Typography variant="h4" color="textPrimary" display="inline">
                                                .5
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary" display="inline">
                                                /month
                                            </Typography>
                                        </div>
                                        <Typography variant="h6" color="textSecondary" display="inline">
                                                Billed annually at $102/year
                                        </Typography>
                                    </React.Fragment>
                                    ) : (
                                        <div>
                                            <Typography variant="h3" color="textPrimary" display="inline">
                                                $10
                                            </Typography>
                                            <Typography variant="h6" color="textSecondary" display="inline">
                                                /month
                                            </Typography>
                                        </div>
                                    )}
                                    </div>
                                    <ul style={styles.featureUl}>
                                        <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                            Connect unlimited printers
                            </Typography>
                                        <Typography component="li" variant="subtitle1" align="center" style={styles.featureLi}>
                                            Everything else in the PRO plan
                            </Typography>
                                    </ul>
                                </CardContent>
                                {this.props.currentUser && <CardActions style={styles.pricingAction}>
                                    {this.proPlusPlanActions()}
                                </CardActions>}
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        )
    }

    render() {
        if (this.state.loading) {
            return <div className="spinner" />
        }

        return (
            <div>
                {this.subscription()}
                <br /><br />
                <Container maxWidth="lg" component="main">
                    <Divider />
                    <div style={styles.note}>
                        <InfoIcon />
                        &nbsp; &nbsp; Your billing and payment information will be
                        safely and securely handled by &nbsp;
                    <a
                            style={styles.link}
                            href="https://www.chargebee.com/"
                            target="_blank"
                        >
                            Chargebee
                    </a>, a &nbsp;
                    <a
                            style={styles.link}
                            href="https://www.chargebee.com/security/pci/"
                            target="_blank"
                        >
                            PCI-compliant
                    </a>
                        &nbsp; payment gateway.
                </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    return {
        currentUser,
    }
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUser,
        },
        dispatch
    );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubscriptionComponent);
