import React from 'react';
import { connect } from "react-redux";
import Divider from 'material-ui/Divider';
import Drawer from 'material-ui/Drawer';
import FontIcon from "material-ui/FontIcon";
import AppBar from 'material-ui/AppBar';
import {List, ListItem} from 'material-ui/List';

import { ProTrialAlert } from "./Pro";
import OctolapseAlert from './OctolapseAlert';
import styles from './styles';

class NavComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    handleToggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        if (this.props.currentUser) {
            const {id, email} = this.props.currentUser;
            gtag('set', {'user_id': id});
            gtag('config', 'UA-133768246-2', {
                'user_id': id
              });
        }

        let title = 'OctoPrint Anywhere';
        if (window.location.href.toLowerCase().indexOf('printjobs') >= 0) {
            title = 'Time-lapses';
        }
        if (window.location.href.toLowerCase().indexOf('devices') >= 0) {
            title = 'Dashboard';
        }
        if (window.location.href.toLowerCase().indexOf('subscription') >= 0) {
            title = 'Subscription';
        }
        if (window.location.href.toLowerCase().indexOf('preferences') >= 0) {
            title = 'Preferences';
        }

        return (
            <div>
                 { this.props.currentUser && <ProTrialAlert currentUser={this.props.currentUser} />}
                <AppBar title={title} onLeftIconButtonClick={() => this.handleToggle()} />
                <Drawer docked={false} onRequestChange={(open) => this.setState({open})} open={this.state.open}>
                    <AppBar
                        className="left-nav"
                        iconClassNameLeft="icon-mui-arrow_back"
                        onLeftIconButtonClick={() => this.handleToggle()}
                        onTitleClick={() => this.handleToggle()}
                        title={this.props.currentUser ? this.props.currentUser.email : ''}
                    />
                    <List>
                        { this.props.currentUser && <a href='/app/devices'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-dashboard' />}>Dashboard</ListItem>
                        </a> }
                        { this.props.currentUser && <a href='/app/printjobs'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-theaters' />}>Time-lapse</ListItem>
                        </a> }
                        { this.props.currentUser && <a href='/app/gcodes'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-stack' />}>G-Code Files</ListItem>
                            </a>}
                        { this.props.currentUser &&
                            <ListItem leftIcon={<FontIcon className='icon-mui-account_box' />}
                            primaryText="Account"
                            primaryTogglesNestedList={true}
                            nestedListStyle={styles.subMenu}
                            nestedItems={[
                                <a href='/app/preferences' key='1'>
                                    <ListItem style={styles.subMenuItem}>Preferences</ListItem>
                                </a>,
                                <a href='/app/subscription' key='2'>
                                    <ListItem style={styles.subMenuItem}>Subscription</ListItem>
                                </a>
                            ]}
                            />}
                        { !this.props.currentUser && <a href='/pub/login'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-enter' />}>Log In</ListItem>
                        </a> }
                        <Divider />
                        <a href='/pub/pricing'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-monetization_on' />}>Pricing</ListItem>
                        </a>
                        <a href='/docs/help'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-info_outline' />}>Help</ListItem>
                        </a>
                        <a href='/docs/blog/'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-description' />}>Blog</ListItem>
                        </a>
                        { this.props.currentUser && this.props.currentUser.hasPro && <a href='mailto:support@getanywhere.io'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-mail_outline' />}>Contact Us</ListItem>
                        </a> }
                        <a href='/docs/about'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-description' />}>About Us</ListItem>
                        </a>
                        <Divider />
                        { this.props.currentUser && <a href='/pub/logout'>
                            <ListItem leftIcon={<FontIcon className='icon-mui-exit' />}>Log Out</ListItem>
                        </a> }
                    </List>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
    };
};

export default connect(
    mapStateToProps,
    null,
)(NavComponent);
