import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from 'lodash/get';
import pick from "lodash/pick";
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';

import { fetchUser } from "../../actions";

import styles from './styles'

class PreferencesComponent extends React.Component {

    initState = {
        msgOpen: false,
        notificationEmail: undefined,
        notifyOnDone: undefined,
        notifyOnCancelled: undefined,
        emailError: undefined,
     };
    state = this.initState;

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    emailChanged = (event, newValue) => {
        const errorText = newValue.length == 0 ? 'Email address is required' :
            this.validateEmail(newValue) ? null : 'Invalid Email address';
        this.setState({ notificationEmail: newValue, emailError: errorText });
    }

    notifyOnDoneChanged = (event, isInputChecked)  => {
        let notifyOnCancelled = this.state.notifyOnCancelled;
        if (!isInputChecked) {
            notifyOnCancelled = false;
        }
        this.setState({ notifyOnDone: isInputChecked, notifyOnCancelled });
    }

    userObjectFromState = () => {
        const obj = {};
        ['notificationEmail', 'notifyOnDone', 'notifyOnCancelled'].forEach( key => {
            if (this.state[key] !== undefined) {
                obj[key] = this.state[key];
            }
        });
        return obj;
    }

    canSubmit = () => {
        return Object.getOwnPropertyNames(this.userObjectFromState()).length > 0
            && !this.state.emailError
    }

    onCancel = () => {
        this.setState(this.initState);
    }
    savePreferences = () => {
        fetch("/api/user/", {
            credentials: "include",
            method: "PATCH",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.userObjectFromState())
        })
            .then(result => result.json())
            .then(user => {
                this.props.fetchUser();
                this.setState({ msgOpen: true });
            });
    }

    render() {
        const email = this.state.notificationEmail !== undefined ? this.state.notificationEmail :
            (get(this.props, 'currentUser.notificationEmail')
                || get(this.props, 'currentUser.email', ''));

        const notifyOnDone = this.state.notifyOnDone !== undefined ? this.state.notifyOnDone : get(this.props, 'currentUser.notifyOnDone', false);
        const notifyOnCancelled = this.state.notifyOnCancelled !== undefined ? this.state.notifyOnCancelled : get(this.props, 'currentUser.notifyOnCancelled', false);
        return (

            <div style={styles.pageContainer}>
                <div className='row'>
                    <div className='col-xs-12 col-md-4 col-md-offset-4'>
                        <h2>Email Notifications</h2>
                        <TextField
                            floatingLabelText="Send notification to this Email address"
                            errorText={this.state.emailError}
                            value={email}
                            onChange={this.emailChanged}
                            style={styles.formInput}
                        />
                        <Toggle
                            label="Email me after print is done"
                            toggled={notifyOnDone}
                            onToggle={this.notifyOnDoneChanged}
                            style={styles.formInput}
                        />
                        <Toggle
                            label="Also Email me after print is cancelled"
                            toggled={notifyOnCancelled}
                            onToggle={(event, isInputChecked) => this.setState({ notifyOnCancelled: isInputChecked })}
                            disabled={!notifyOnDone}
                            style={styles.formInput}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12 col-md-4 col-md-offset-4'>
                        <br /><br />
                        <RaisedButton label="Save" primary={true} disabled={!this.canSubmit()} onClick={() => this.savePreferences()} style={styles.button} />
                        <RaisedButton label="Cancel" onClick={() => this.onCancel()} style={styles.button} />
                    </div>
                </div>
                <Snackbar
                    open={this.state.msgOpen}
                    message="Notification preferences updated!"
                    autoHideDuration={2000}
                    onRequestClose={() => this.setState(this.initState)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUser,
        },
        dispatch
    );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreferencesComponent);
