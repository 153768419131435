import "isomorphic-fetch";
import React from "react";
import { render } from "react-dom";
import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware, bindActionCreators } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import logger from "redux-logger";
import moment from 'moment';
import { BrowserRouter as Router, Route } from "react-router-dom";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import "./global-assets/js/chargebee";

import Logout from "./components/common/Logout";
import Login from "./components/common/Login";
import { initBugSnag } from "./errors";

import PreOrderSubscriptionClosed from "./components/guest/PreOrderSubscriptionClosed";
import DevicesMain from "./components/devices/DevicesMain";
import GCodesMain from "./components/gcodes/GCodesMain";
import PrintJobsMain from "./components/printjobs/PrintJobsMain";
import Subscription from "./components/account/Subscription";
import Preferences from "./components/account/Preferences";
import DeviceGuestMain from "./components/guest/DeviceGuestMain";
import PrintJobGuestMain from "./components/guest/PrintJobGuestMain";
import NotifUnsubscribe from "./components/guest/NotifUnsubscribe";
import OnboardingMain from "./components/onboarding/Main";
import Consent from "./components/common/Consent";
import FreeServiceStopped from "./components/common/FreeServiceStopped";
import Nav from "./components/common/Nav";

import { checkDeviceStatus, fetchUser } from "./actions";
import "./global-assets/scss";
import "./global-assets/font-icons/style.css";
import "./global-assets/images/logo.png";
import "./global-assets/images/logo_inverse.png";

const SECOND_IN_MS = 1000;

const initState = {
};

const store = createStore(reducers, initState, applyMiddleware(thunk, logger));
class Footer extends React.Component {
    render() {
        return (
        <footer className="nav-footer" id="footer">
            <section>
                <div style={{margin: '-1rem 0 1rem', backgroundColor: '#ffb74d'}}>
                    OctoPrint Anywhere has been succeeded by <a href="https://www.thespaghettidetective.com/" style={{color: '#9965f4', padding: 'unset'}}>The Spaghetti Detective</a>. <a href="https://octoprintanywhere.thespaghettidetective.com/docs/blog/2020/01/31/moving-forward/" style={{color: '#9965f4'}}>Learn more >>></a>
                </div>
            </section>
            <section className="copyright">This website uses cookies, and also collects some information using Google Analytics. </section>
            <section className="copyright">Copyright © 2021 OctoPrint Anywhere</section>
            <section>
                <a href="/docs/docs/terms">Terms Of Use</a>
                <a href="/docs/docs/privacy">Privacy</a>
                <a href="/docs/docs/disclaimer">Disclaimer</a>
            </section>
        </footer>
        );
    }
}

class AppComponent extends React.Component {

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        const { match, location, currentUser } = this.props;
        if (!currentUser) {
            return (<div className="spinner" />);
        }
        const shouldLetIn = currentUser.hasPro || location.pathname.match(/subscription|consent/i) || moment(currentUser.createdAt).isAfter(moment().subtract(14, 'd'));
        if (!shouldLetIn) {
            return (

            <MuiThemeProvider>
            <div style={{position: 'relative', minHeight: '100vh'}}>
                <Nav />
                <div style={{paddingBottom: '200px'}}>
                    <FreeServiceStopped />
                </div>
                <Footer />
                </div>
            </MuiThemeProvider>
            )
        }
        return (
            <MuiThemeProvider>
                <div style={{position: 'relative', minHeight: '100vh'}}>
                    <Nav />
                    <div style={{paddingBottom: '200px'}}>
                        <Route
                            path={`${match.path}/devices`}
                            component={DevicesMain}
                        />
                        <Route
                            path={`${match.path}/gcodes`}
                            component={GCodesMain}
                        />
                        <Route
                            path={`${match.path}/printjobs`}
                            component={PrintJobsMain}
                        />
                        <Route
                            path={`${match.path}/preferences`}
                            component={Preferences}
                        />
                        <Route
                            path={`${match.path}/subscription/:from?`}
                            render={props => <Subscription {...props} />}
                        />
                        <Route path={`${match.path}/consent`} component={Consent} />
                    </div>
                    <Footer />
                </div>
            </MuiThemeProvider>
        );
    }
}

const mapStateToPropsApp = (state) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToPropsApp = dispatch => {
    return bindActionCreators(
        {
            fetchUser,
        },
        dispatch
    );
};

const App = connect(
    mapStateToPropsApp,
    mapDispatchToPropsApp
)(AppComponent);

class Pub extends React.Component {

    render() {
        const { match } = this.props;
        return (
            <MuiThemeProvider>
                <div style={{position: 'relative', minHeight: '100vh'}}>
                    <Nav />
                    <div style={{paddingBottom: '200px'}}>
                        <Route path={`${match.path}/logout`} component={Logout} />
                        <Route path={`${match.path}/login`} component={Login} />
                        <Route
                            path={`${match.path}/guest/:sharedToken`}
                            component={DeviceGuestMain}
                        />
                        <Route
                            path={`${match.path}/guesttl/:sharedToken`}
                            component={PrintJobGuestMain}
                        />
                        <Route path={`${match.path}/pricing`} component={Subscription} />
                        <Route
                            path={`${match.path}/link_printer`}
                            component={OnboardingMain}
                        />
                        <Route
                            path={`${match.path}/notif_unsubscribe`}
                            component={NotifUnsubscribe}
                        />
                        <Route path={`${match.path}/preorder-sub`} component={PreOrderSubscriptionClosed} />
                    </div>
                    <Footer />
                </div>
            </MuiThemeProvider>
        );
    }
}

fetch("/api/config")
    .then(result => result.json())
    .then(cfg => {
        window.config = cfg;
        configGlobalServices();
        setInterval(
            () => store.dispatch(checkDeviceStatus()),
            5 * SECOND_IN_MS
        );

        render(
            <Provider store={store}>
                <Router>
                    <div>
                        <Route path="/app" component={App} />
                        <Route path="/pub" component={Pub} />
                    </div>
                </Router>
            </Provider>,
            document.querySelector("#app")
        );
    });

function configGlobalServices() {
    initBugSnag();
}
