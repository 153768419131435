import get from 'lodash/get'
import includes from 'lodash/includes'
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import Dialog from 'material-ui/Dialog'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Slider from 'material-ui/Slider'

import styles from './styles'

export class TemperatureDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            temperature: get(
                this.props.current,
                this.props.temperatureToChange + '.target',
                null,
            ),
        }
    }

    presetSelected = (event, index, value) => {
        this.setState({
            temperature: value,
        })
    }

    selectedPreset = () => {
        if (this.state.temperature == 0) {
            return null
        }

        const presetTemps = this.props.presets.map(preset => {
            return this.props.temperatureToChange == 'bed'
                ? preset.bed
                : preset.extruder
        })

        return includes(presetTemps, this.state.temperature)
            ? this.state.temperature
            : null
    }

    tempStr = temp => {
        if (!temp || isNaN(temp) || temp <= 1) {
            // For some reason OctoPrint will send 1 when it's actually off
            return 'OFF'
        }
        console.log(temp)
        return parseFloat(temp).toFixed() + '°C'
    }

    temperatureForPreset = preset => {
        return this.props.temperatureToChange == 'bed'
            ? preset.bed
            : preset.extruder
    }

    textForPreset = preset => {
        return `${preset.name} (${this.tempStr(
            this.temperatureForPreset(preset),
        )})`
    }

    handleTemperatureSlider = (event, value) => {
        this.setState({ temperature: value })
    }

    render() {
        const actions = [
            <FlatButton
                label="CANCEL"
                primary={true}
                keyboardFocused={true}
                onClick={() => this.props.cancelled()}
            />,
            <FlatButton
                label="CONFIRM"
                primary={false}
                onClick={() => this.props.confirmed(this.state.temperature)}
            />,
        ]
        return (
            <Dialog
                title={this.tempStr(this.state.temperature)}
                open={true}
                actions={actions}
                onRequestClose={() => this.props.cancelled()}
                modal={false}
                titleStyle={styles.dialogTitle}
            >
                <SelectField
                    fullWidth={true}
                    floatingLabelText="Preset"
                    value={this.selectedPreset()}
                    onChange={this.presetSelected}
                >
                    <MenuItem value={0} primaryText="OFF" />
                    {this.props.presets.map((preset, i) => (
                        <MenuItem
                            key={i}
                            value={this.temperatureForPreset(preset)}
                            primaryText={this.textForPreset(preset)}
                        />
                    ))}
                </SelectField>
                <Slider
                    min={0}
                    max={this.props.temperatureToChange == 'bed' ? 150 : 500}
                    step={1}
                    value={this.state.temperature}
                    onChange={this.handleTemperatureSlider}
                />
            </Dialog>
        )
    }
}
