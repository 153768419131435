export function getLocalStorageItem(key, defaultValue) {
    if (typeof Storage !== 'undefined') {
        return localStorage.getItem(key) || defaultValue;
    }
    return defaultValue;
}

export function saveLocalStorageItem(key, value) {
    if (typeof Storage !== 'undefined') {
        localStorage.setItem(key, value);
    }
}

export const DASHBOARD_CARD_SORTING = 'DASHBOARD_CARD_SORTING';
export const WEBCAM_FEED_WARNING_DISMISSED = 'WEBCAM_FEED_WARNING_DISMISSED';
export const TIMELPAPSE_ANNOUNCE_SHOWN = 'TIMELPAPSE_ANNOUNCE_SHOWN';
export const CHROME_BUG_ANNOUNCE_SHOWN = 'CHROME_BUG_ANNOUNCE_SHOWN';
