import React from "react";
import { connect } from "react-redux";
import map from 'lodash/map'
import moment from 'moment';
import filesize from 'filesize';

import MaterialTable from 'material-table';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';

import { fetchDevices } from "../../actions";
import styles from "./styles";
import UploadDialog from "./UploadDialog";
import PrintDialog from "./PrintDialog";

const theme = createMuiTheme({
    palette: {
      primary: cyan,
    },
  });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

class GCodesMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            uploadOpen: false,
            filesToDelete: [],
            fileToPrint: null,
            files: [],
        };
    }

    componentDidMount() {
        this.props.fetchDevices();
        this.refreshGCodefiles();
    }

    refreshGCodefiles() {
        this.setState({loading: true});
        fetch('/api/gcodes', { credentials: 'include' })
            .then(result => result.json())
            .then(files => {
                this.setState({ files, loading: false });
            });
    }

    uploadDialogClosed = () => {
        this.setState({uploadOpen: false});
        this.refreshGCodefiles();
    }

    addBtnClicked = () => {
        this.setState({uploadOpen: true});
    }

    deleteCancelled = () => {
        this.setState({filesToDelete: []});
    }

    deleteConfirmed = () => {
        fetch(`/api/gcodes?id=${JSON.stringify(map(this.state.filesToDelete, 'id'))}`, {
            credentials: "include",
            method: "DELETE",
        })
        .then(result => result.json())
        .then(files => {
            this.setState({files});
        })
        this.setState({filesToDelete: []});
    }

    deleteGCodeFile = (rowData) => {
        this.setState({filesToDelete: [rowData]});
    }

    deleteGCodeFiles = (rows) => {
        this.setState({filesToDelete: rows});
    }

    printDialogClosed = () => {
        this.setState({fileToPrint: null});
    }

    gcodeFileRowDetailPanel = (rowData) => {
        return (
            <Grid container alignItems="center" style={{...styles.detailPanel, background: grey[200]}}>
                <Grid item xs={12} sm={9} lg={10} >
                    <p style={styles.detailPanelTitle}>{rowData.filename}</p>
                    <p><span style={styles.detailPanelFieldName}>Uploaded: </span>{moment(rowData.createdAt).format("LLL")}</p>
                    <p><span style={styles.detailPanelFieldName}>Size: </span>{rowData.bytes} bytes</p>
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <div style={{textAlign: 'right'}}>
                    <Button color="primary" style={styles.detailPanelActionBtn} onClick={() => this.setState({fileToPrint: rowData})}>
                        <PrintIcon />
                        &nbsp;Print...
                    </Button>
                    </div>
                    <div style={{textAlign: 'right'}}>
                    <Button color="secondary" style={styles.detailPanelActionBtn} onClick={() => this.deleteGCodeFile(rowData)}>
                        <DeleteIcon />
                        &nbsp;Delete...
                    </Button>
                    </div>
                </Grid>
            </Grid>
        );
    }
    render() {
        return (
            <ThemeProvider theme={theme}>
            <Container maxWidth="md" style={styles.outterContainer}>
                <Fab
                    color="primary"
                    aria-label="Add"
                    style={{...styles.fabBtnInit, ...(this.state.loading ? {} : styles.fabBtn)}}
                    onClick={this.addBtnClicked}
                >
                    <AddIcon />
                </Fab>
                <UploadDialog open={this.state.uploadOpen} onClose={this.uploadDialogClosed} />
                <MaterialTable
                    data={this.state.files}
                    detailPanel={this.gcodeFileRowDetailPanel}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    columns={[
                        {
                            title: 'File name',
                            field: 'filename',
                            headerStyle: styles.cellStyle,
                            cellStyle: styles.cellStyle,
                        },
                        {
                            title: 'Uploaded',
                            field: 'createdAt',
                            type: 'datetime',
                            headerStyle: styles.cellStyle,
                            cellStyle: styles.cellStyle,
                            render: rowData => {
                                const uploaded = moment(rowData.createdAt);
                                return uploaded.diff(new Date(), 'hours') < -24 ? uploaded.format("MMM Do YYYY"): uploaded.fromNow()
                            },
                        },
                        {
                            title: 'Size',
                            field: 'bytes',
                            type: 'numeric',
                            headerStyle: styles.cellStyle,
                            cellStyle: styles.cellStyle,
                            render: rowData => filesize(rowData.bytes),
                        }
                    ]}
                    options={{
                        selection: true,
                        pageSize: 15,
                        pageSizeOptions: [10, 15, 50, 100],
                        columnsButton: true,
                        showTitle: false,
                      }}
                    actions={[
                    {
                        tooltip: 'Remove All Selected GCode Files',
                        icon: 'delete',
                        onClick: (evt, data) => this.deleteGCodeFiles(data),
                    }
                    ]}
                    localization={{
                        toolbar: {
                            nRowsSelected: '{0} file(s) selected',
                        },
                        body: {
                            emptyDataSourceMessage: 'You have not uploaded any GCode files.',
                        },
                    }}
                />
                <Dialog
                    open={this.state.filesToDelete.length > 0}
                    onClose={this.deleteCancelled}
                    fullScreen={this.props.fullScreen}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete {this.state.filesToDelete.length} GCode file(s) from OctoPrint Anywhere. Are you sure?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.deleteCancelled} autoFocus>
                        No
                    </Button>
                    <Button onClick={this.deleteConfirmed} color="primary">
                        Yes
                    </Button>
                    </DialogActions>
                </Dialog>
                { this.state.fileToPrint != null && <PrintDialog fileToPrint={this.state.fileToPrint} onClose={this.printDialogClosed} />}
            </Container>
            </ThemeProvider>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {devices} = state;
    return {
        devices,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDevices: () => {
            dispatch(fetchDevices());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(GCodesMainComponent));
