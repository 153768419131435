import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import filter from 'lodash/filter'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Popover from 'material-ui/Popover'
import Toggle from 'material-ui/Toggle'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import FontIcon from 'material-ui/FontIcon'
import Snackbar from 'material-ui/Snackbar';

import { updateDevices } from '../../actions'

import * as colors from 'material-ui/styles/colors'
import commonStyles from '../common/styles'

class OctolapseAlertComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            octolapseHelpOpen: false,
            userOpted: false,
        }
    }

    octolapseHelp = event => {
        // This prevents ghost click.
        event.preventDefault()

        this.setState({
            octolapseHelpOpen: true,
            anchorEl: event.currentTarget,
        })
    }
    octolapseHelpClose = () => {
        this.setState({
            octolapseHelpOpen: false,
        })
    }

    octolapseOptedIn = () => {
        this.updateOctolapseOptIn(true)
        this.setState({ userOpted: true })
    }

    octolapseOptedOut = () => {
        this.updateOctolapseOptIn(false)
        this.setState({ userOpted: true })
    }

    updateOctolapseOptIn(optedIn) {
        this.originalOpted = this.props.octolapseOpted
        this.props.updateDevices(
            this.props.devices.map(device => ({
                ...device,
                ...{ octolapseOptedIn: optedIn },
            })),
        )
    }

    render() {
        const shouldShowAlert = this.props.currentUser && !this.props.currentUser.hasPro;

        const shouldShowSnackbar = false
        return (
            <div>
            <ReactCSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={500}
            >
            { !shouldShowAlert ?  null : (
                <div
                    key="1"
                    style={{
                        background: 'rgb(255, 252, 108)',
                        position: 'relative',
                        padding: '14px',
                    }}
                >
                    <div style={{ padding: '3px', textAlign: 'center' }}>
                        OctoPrint Anywhere will be succeeded by&nbsp;
                        <a
                            href="https://www.thespaghettidetective.com/?utm_source=OA&utm_medium=page&utm_campaign=transition"
                            style={commonStyles.link}
                        >
                            The Spaghetti Detective
                        </a>
                        , a better webcam streaming tool with AI-based print failure detection.
                    </div>
                    <div style={{ padding: '3px', textAlign: 'center' }}>
                        Please <a href="https://www.getanywhere.io/docs/docs/transition-to-tsd" style={commonStyles.link}>uninstall OctoPrint Anywhere and install The Spaghetti Detective plugin</a> as soon as possible.
                    </div>
                    <div style={{ padding: '3px', textAlign: 'center' }}>
                        <RaisedButton
                            label="Details"
                            href="https://www.getanywhere.io/docs/blog/2019/12/25/transition-to-tsd/"
                            style={{ margin: '0px 30px' }}
                        />
                    </div>
                </div> )}
            </ReactCSSTransitionGroup>
                    <Snackbar
            open={shouldShowSnackbar}
          message='Octolapse setting changed'
          autoHideDuration={6000}
        />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    const octolapseEnabled =
        filter(state.deviceStatus, devStatus =>
            get(devStatus, 'octolapse.enabled', false),
        ).length > 0
    const octolapseOpted =
        filter(state.devices, device => device.octolapseOptedIn != null)
            .length > 0

    return {
        currentUser,
        octolapseEnabled,
        octolapseOpted,
        devices: state.devices,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateDevices: devices => {
            dispatch(updateDevices(devices))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(
    OctolapseAlertComponent,
)
