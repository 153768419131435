import React from "react";
import RaisedButton from "material-ui/RaisedButton";

import throwOnError from "../../utils/throwOnError";
import PrintJobCard from "../printjobs/PrintJobCard";

import VomitingSnap from '../../global-assets/images/vomiting_snap.png'
import commonStyles from "../common/styles";
import styles from "./styles";

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            printJob: null,
            notFound: false
        };
    }

    componentDidMount() {
        const token = this.props.match.params.sharedToken;
        fetch(`/api/printJobs/${token}/pub`)
            .then(throwOnError)
            .then(result => result.json())
            .then(printJob => {
                this.setState({ printJob });
            })
            .catch(() => {
                this.setState({ notFound: true });
            });
    }

    notFound() {
        return (
                    <div className="col-xs-8 col-sm-6 col-md-4 col-lg-2">
                        <img src={VomitingSnap} />
                        <div>This shared link is no longer valid.</div>
                    </div>
        );
    }

    render() {
        return (
            <div className="card-container">
                <div className="row center-xs center-sm center-md center-lg">
                    {this.state.notFound ? (
                        this.notFound()
                    ) : this.state.printJob ? (
                        <PrintJobCard
                            printJob={this.state.printJob}
                            asGuest={true}
                        />
                    ) : (
                        <div className="spinner" />
                    )}
                </div>
                <div
                    style={styles.footnote}
                    className="row center-xs center-sm center-md center-lg"
                >
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        This time-lapse is shared using{" "}
                        <a
                            style={commonStyles.link}
                            href="https://plugins.octoprint.org/plugins/anywhere/"
                        >
                            OctoPrint Anywhere
                        </a>.
                    </div>
                </div>
            </div>
        );
    }
}
