import React from 'react'
import { Link } from 'react-router-dom'
import ReportIcon from '@material-ui/icons/Report';
import red from '@material-ui/core/colors/red';
import Box from '@material-ui/core/Box';

import commonStyles from '../common/styles'

export default class Consent extends React.Component {

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    margin: '24px 12px',
                }}
            >
                <div style={{ margin: 'auto' }}>
                    <div style={{width: '100%', textAlign: 'center' }}>
                    <ReportIcon style={{ color: red[500], fontSize: 64}} />
                    </div>
                    <br />
                    <p>OctoPrint Anywhere has been succeeded by <a
                                href="https://www.thespaghettidetective.com/"
                                style={commonStyles.link}
                            >The Spaghetti Detective</a>.</p>
                    <p><a href="https://www.getanywhere.io/docs/docs/transition-to-tsd" style={commonStyles.link}>Moving to The Spaghetti Detective</a> only takes a couple minutes.</p>
                    <p>If you want to keep using OctoPrint Anywhere, you need to <Link
                                to="/app/subscription"
                                style={commonStyles.link}
                                >upgrade to PRO or PRO+ plan</Link>. OctoPrint Anywhere is now a subscription-only service.</p>
                    <p>Learn more about <a style={commonStyles.link} href="/docs/blog/2020/01/31/moving-forward">why this decision was made, and the plan to bring OctoPrint Anywhere onward.</a></p>
                    <br />
                    <Box fontStyle="italic" m={1}>- Kenneth Jiang, Founder of OctoPrint Anywhere & The Spaghetti Detective</Box>
                </div>
            </div>
        )
    }
}
