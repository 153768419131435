import React from 'react';

import styles from '../common/styles';

export default class extends React.Component {

    render() {
        return (
          <div className='container'>
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='logout-paper col-sm-12 col-md-8 center'>
                <h1>Presubscription for the premium service is now closed.</h1>
                <p>You can subscribe it once the service goes live. Keep an eye out for the announcement email.</p>
                <p>You may want to add "admin@getanywhere.io" to your contact list so emails from us won't end up in spam folder. </p>
                </div>
            </div>
          </div>
        );
    }
}
