import has from "lodash/has";
import omit from "lodash/omit";

import { logError } from "./errors";

export const WS_MESSAGE_RECEIVED = "WS_MESSAGE_RECEIVED";
export const UPDATE_DEVICE_STATUS = "UPDATE_DEVICE_STATUS";
export const DEVICE_LIST_FETCH_REQUEST = "DEVICE_LIST_FETCH_REQUEST";
export const DEVICE_LIST_FETCH_SUCCESS = "DEVICE_LIST_FETCH_SUCCESS";
export const USER_FETCH_REQUEST = "USER_FETCH_REQUEST";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";

export const wsMessageReceived = (msg, deviceId) => ({
    type: WS_MESSAGE_RECEIVED,
    msg,
    deviceId
});

export const updateDeviceStatus = deviceId => ({
    type: UPDATE_DEVICE_STATUS,
    deviceId
});

var webSockets = new Map();

export const subscribeToDevice = deviceId => {
    return subscribeToWebSocket(`/app/ws/user/${deviceId}`, deviceId);
};

export const subscribeToDeviceAsGuest = (sharedToken, deviceId) => {
    return subscribeToWebSocket(`/app/ws/guest/${sharedToken}`, deviceId);
};

const subscribeToWebSocket = (wsPath, deviceId) => {
    return dispatch => {
        if (webSockets.has(deviceId.toString())) {
            return;
        }

        const config = window.config;
        const host = window.document.location.host.replace(/:.*/, "");
        const ws = new WebSocket(
            `${config.WS_PREFIX}${host}${config.WS_PORT || ""}${wsPath}`
        );

        webSockets.set(deviceId.toString(), ws);

        ws.onmessage = event => {
            const data = JSON.parse(event.data);

            if (!data) {
                return;
            } // Some text messages are not in json format

            dispatch(wsMessageReceived(data, deviceId));
        };

        ws.onerror = e => {
            ensureWebSocketClosed(deviceId);
            logError("Websocket error: " + JSON.stringify(e));
        };

        ws.onclose = e => {
            ensureWebSocketClosed(deviceId);
            !e.wasClean &&
                logError(`WebSocket closed with error: ${e.code} ${e.reason}`);
        };
    };
};

const ensureWebSocketClosed = deviceId => {
    if (webSockets.get(deviceId.toString())) {
        webSockets.get(deviceId.toString()).close();
        webSockets.delete(deviceId.toString());
    }
};

export const unsubscribeFromDevice = deviceId => {
    return dispatch => {
        ensureWebSocketClosed(deviceId);
    };
};

export const sendMessageToDevice = (msg, deviceId) => {
    return dispatch => {
        const ws = webSockets.get(deviceId.toString());
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(msg);
        }
    };
};

export const checkDeviceStatus = () => {
    return dispatch => {
        webSockets.forEach((_, deviceId) => {
            dispatch(updateDeviceStatus(deviceId));
        });
    };
};

export const suspendAllWebSockets = () => {};

export const resumeAllWebSockets = () => {};

// Devices REST APIs

export const deviceListFetchSuccess = devices => ({
    type: DEVICE_LIST_FETCH_SUCCESS,
    devices
});

export const deviceListFetchRequest = () => ({
    type: DEVICE_LIST_FETCH_REQUEST,
});

export const fetchDevices = () => {
    return dispatch => {
        dispatch(deviceListFetchRequest());
        fetch("/api/devices", { credentials: "include" })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status >= 500) {
                        window.location.replace("/assets/outage.html");
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                }
            })
            .then(devices => {
                dispatch(deviceListFetchSuccess(devices));
            });
    };
};

export const updateDevices = devices => {
    return dispatch => {
        Promise.all(
            devices.map(device => {
                return fetch("/api/devices/" + device.id, {
                    credentials: "include",
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(omit(device, ['id']))
                });
            })
        ).then(() => {
            dispatch(fetchDevices());
        });
    };
};
export const deleteDevice = deviceId => {
    return dispatch => {
        fetch("/api/devices/" + deviceId, {
            credentials: "include",
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then(() => {
            dispatch(fetchDevices());
        });
    };
};

// User APIs
export const userFetchSuccess = user => ({
    type: USER_FETCH_SUCCESS,
    user
});

export const userFetchRequest = () => ({
    type: USER_FETCH_REQUEST,
});

export const fetchUser = () => {
    return dispatch => {
        dispatch(userFetchRequest());
        fetch('/api/user', {credentials: 'include', redirect: 'error'})
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                if (response.status >= 500) {
                    window.location.replace("/assets/outage.html");
                } else {
                    return Promise.reject(new Error(response.statusText));
                }
            }
        })
        .then(userProfile => {
            dispatch(userFetchSuccess(userProfile));
        })
        .catch((e) => {
            dispatch(userFetchSuccess(null))
        });
    };
};
