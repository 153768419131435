import amber from '@material-ui/core/colors/amber';
import cyan from '@material-ui/core/colors/cyan';

export default {
    link: {
        color: "#33c9dc",
        textDecoration: "underline"
    },

    hintContainer: {
        display: "flex",
        alignItems: "baseline"
    },

    hintTarget: {
        width: "auto",
    },

    hint: {
        fontSize: "12px",
        color: "#33c9dc",
        textDecoration: "underline",
        marginLeft: "3px",
    },
    checkableMenuItem: {
        padding: "0px 16px 0px 47px"
    },

    red: {
        color: 'rgb(255, 64, 129)'
    },

    subMenu: {
        padding: "0px",
    },

    subMenuItem: {
        padding: "0px 0px 0px 56px",
    },

    lastRow: {
        marginBottom: "120px",
    },

    notifBar: {
        backgroundColor: amber[500],
        height: "40px",
        color: "rgb(255, 255, 255)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: '6px 0px',
    },

    notifBarLink: {
        color: cyan[500],
        textDecoration: "underline",
        marginLeft: "5px",
    }
};
