import videojs from 'video.js';
import React from "react";
import FontIcon from "material-ui/FontIcon";
import { IconButton } from 'material-ui';

const styles = {
    fullScreenBtnContainer: {
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: "1000",
    },
    fullScreenBtn: {
        width: 48,
        height: 48,
        padding: 6,
        background: "rgba(128,128,128,0.2)",
    },
    smallIcon: {
        width: 24,
        height: 24
    },
}
export default class VideoPlayer extends React.Component {
    componentDidMount() {
        const self = this;
        // instantiate Video.js
        videojs.addLanguage('en', { 'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Snap! The video stream is broken. Please reload this page. If this error persists, please contact us support@getanywhere.io' });
        self.player = videojs(self.videoNode, self.props, function onPlayerReady() {
            if (self.props.autoplay) {
                const playPromise = this.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        console.log("started");
                    })
                }
            }
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    toggleFullscreen = () => {
        this.player.requestFullscreen();
    }
    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div style={{ position: "relative" }}>
                <div style={styles.fullScreenBtnContainer}>
                    <IconButton
                        style={styles.fullScreenBtn}
                        iconStyle={styles.smallIcon}
                        onClick={this.toggleFullscreen}
                    >
                        <FontIcon
                            className="icon-mui-fullscreen"
                            color="#eee"
                        />
                    </IconButton>
                </div>
                <div data-vjs-player>
                    <video ref={node => this.videoNode = node} className="video-js vjs-big-play-centered"></video>
                </div>
            </div>
        )
    }
}
