import React from "react";
import forEach from 'lodash/forEach'
import Dropzone from 'react-dropzone';
import superagent from 'superagent';
import update from "immutability-helper";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Snackbar from '@material-ui/core/Snackbar';

import styles from "./styles";
import { Grid } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default class UploadDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            rejectedFiles: [],
        };
    }

    onClose = () => {
        this.setState({
            files: [],
        });
        this.props.onClose();
    }

    onRejected = (rejectedFiles) => {
        this.setState({rejectedFiles});
    }

    onDrop = (acceptedFiles) => {
        const existingFilesNum = this.state.files.length;
        const newFiles = [];
        forEach(acceptedFiles, (file, i) => {
            newFiles.push({name: file.name, progress: 0});
            const fileIndex = existingFilesNum + i;
            const req = superagent.post('/api/gcodes');
            req.attach(file.name, file)
                .on('progress', (e) => {
                    const newState = update(this.state, {
                        files: {
                            [fileIndex]: {
                                progress: {$set: e.percent }
                            }
                        }
                    });
                    this.setState(newState);
                })
                .end();
        });
        const newState = update(this.state, {
            files: {$push: newFiles},
        });
        this.setState(newState);
    }

    render() {
        return (
            <Dialog fullScreen open={this.props.open} TransitionComponent={Transition}>
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: 1, }}>
                            Upload GCode Files
                        </Typography>
                        <Button color="inherit" onClick={this.onClose}>
                            Done
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="md" style={styles.outterContainer}>
                <Dropzone
                    onDropAccepted={this.onDrop}
                    onDropRejected={this.onRejected}
                    accept='.gco,.gcode'
                >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        const dropzonStyles = {...styles.dropzone, ...(isDragActive ? styles.dropzonActive : {})};
                        return (
                        <div {...getRootProps({style: dropzonStyles})}>
                            <input {...getInputProps()} />
                            {!isDragActive && 'Click here or drop GCode files (*.gco, *.gcode) to upload!'}
                            {isDragActive && "Drop it like it's hot!"}
                            <CloudUploadIcon style={styles.dropIcon}/>
                            <Grid container spacing={4}>
                                {this.state.files.map((f, i) => (
                                    <Grid item xs={12} key={i} >
                                        <div style={styles.uploadFilename}><div>{f.name}</div>{ f.progress > 99 && <CheckCircleIcon color='primary' /> }</div>
                                        <LinearProgress variant="determinate" value={f.progress} />
                                    </Grid>
                                )
                                )}
                            </Grid>
                        </div>
                        )
                    }}
                </Dropzone>
                <Snackbar
                    open={this.state.rejectedFiles.length > 0}
                    onClose={() => this.setState({rejectedFiles: []})}
                    autoHideDuration={3000}
                    TransitionComponent={Transition}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Oops! Only GCode files can be uploaded.</span>}
                />
                </Container>
            </Dialog>
        )
    }
}
