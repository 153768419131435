import React from "react";
import { connect } from "react-redux";

import GuestDeviceCard from "./GuestDeviceCard";
import throwOnError from "../../utils/throwOnError";

import VomitingSnap from '../../global-assets/images/vomiting_snap.png'
import commonStyles from "../common/styles";
import styles from "./styles";

class DeviceGuestMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false
        };
    }

    componentDidMount() {
        const token = this.props.match.params.sharedToken;
        fetch(`/api/devices/${token}/pub`)
            .then(throwOnError)
            .then(result => result.json())
            .then(device => {
                this.setState({ device });
            })
            .catch(() => {
                this.setState({ notFound: true });
            });
    }
    notFound() {
        return (
            <div>
                <div className="row center-xs center-sm center-md center-lg">
                    <div className="col-xs-8 col-sm-6 col-md-4 col-lg-2">
                <img src={VomitingSnap} />
                <div>This shared link is no longer valid.</div>
            </div>
            </div>
                <div style={styles.footnote} className="row center-xs center-sm center-md center-lg">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            This camera feed is shared using{" "}
                            <a
                                style={commonStyles.link}
                                href="https://plugins.octoprint.org/plugins/anywhere/"
                            >
                                OctoPrint Anywhere
                            </a>.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.notFound ? (
                    this.notFound()
                ) : this.state.device ? (
                    <GuestDeviceCard
                        device={this.state.device}
                        sharedToken={this.props.match.params.sharedToken}
                    />
                ) : (
                    <div className="spinner" />
                )}
            </div>
        );
    }
}
export default connect(
    () => ({}),
    null,
)(DeviceGuestMainComponent);
