import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

import SoLong from '../../global-assets/images/so_long.gif';

export default class extends React.Component {

    render() {
        return (
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='logout-paper col-xs-8 col-sm-6 col-md-4 col-lg-4 center'>
                    <img src={SoLong} />
                    <RaisedButton label="Log back in" style={{margin: '10px'}} primary={true} href='/pub/login' />
                </div>
            </div>
        );
    }
}
