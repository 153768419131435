import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

import commonStyles from '../common/styles'

export default class extends React.Component {

    render() {
        return (
          <div className='container'>
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='col-xs-12 center'>
                <h3>OctoPrint Remote Access</h3>
                <h2>Made Easy</h2>
                </div>
            </div>
            <div className='row center-xs center-sm center-md center-lg'>
            <div className='col-xs-12 col-sm-12 col-md-10 col-lg-8 center' style={{padding: "1rem"}}>
                    <RaisedButton label="Log In or Sign Up" primary={true} fullWidth={true} onClick={() => window.location.href = "/pub/auth0_login"} />
                </div>
            </div>

            <div className='row center-xs center-sm center-md center-lg'>
            <div className='col-xs-12 col-sm-12 col-md-10 col-lg-8' style={{display: 'flex', justifyContent: 'space-between', padding: "0 1rem"}}>
                    <a href="/docs/docs/login-troubles" style={commonStyles.link}>Need help to log in?</a>
                    <a style={{marginLeft: '2rem'}} href="/docs/help/" style={commonStyles.link}>Other helps >>></a>
                </div>
            </div>
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='col-xs-12 col-sm-12 col-md-10 col-lg-8 center video-responsive' style={{margin: "2rem"}}>
                    <iframe width="420" height="315" src="https://www.youtube.com/embed/rewYtchHAc4?origin=https://www.getanywhere.io" frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
          </div>
        );
    }
}
