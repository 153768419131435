import React from 'react';

class ScrollDetector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            atBottom: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            if (!this.state.atBottom) {
                if (this.props.scrollToBottom) {
                    this.props.scrollToBottom();
                }
            }
            this.setState({atBottom: true});
        } else {
            this.setState({atBottom: false});
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return <div />;
    }
}

ScrollDetector.propTypes = {};

ScrollDetector.defaultProps = {};

export default ScrollDetector;
