import React, { Fragment } from "react";
import moment from "moment";
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import { ThemeProvider } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';

import commonStyles from './styles'

import {
    CHROME_BUG_ANNOUNCE_SHOWN,
    saveLocalStorageItem,
    getLocalStorageItem
} from "../../utils/browserLocalStorage";

const theme = createMuiTheme({
    palette: {
        primary: cyan,
    },
});

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}

export class ChromeBugAlertComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertOpen: false
        };
    }

    dlgClosed = () => {
        this.setState({ alertOpen: false });
    }

    donotShow = () => {
        this.dlgClosed();
        saveLocalStorageItem(CHROME_BUG_ANNOUNCE_SHOWN, "true")
    }

    componentDidMount() {
        if (getLocalStorageItem(CHROME_BUG_ANNOUNCE_SHOWN, "false") !== "true") {
            this.setState({ alertOpen: true });
        }
    }

    render() {
        const action = (
            <Fragment>
            <Button color="inherit" size="small" style={{marginLeft: '18px'}} onClick={this.donotShow}>
              Gotcha!
            </Button>
            </Fragment>
          );

        return (
            <ThemeProvider theme={theme}>
            <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={TransitionLeft}
            open={this.state.alertOpen}
            onClose={this.dlgClosed}
            action={action}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Uh-oh, you are using Chrome on an Android device. You may <a style={commonStyles.link} href='/docs/docs/webcam-streaming-on-chrome-android'>experience problems with video streaming.</a></span>}
          />
          </ThemeProvider>
        );
    }
}
