import moment from "moment";
import get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";

import { sendMessageToDevice } from "../../actions";

import styles from "./styles";

class DeviceControlsComponent extends React.Component {

    sendJogCommand(axis, distance_mm) {
        const movement = {};
        movement[axis] = distance_mm;
        this.props.sendMessageToDevice(
            JSON.stringify({ cmd: { jog: movement } }),
            this.props.device.id
        );
    }

    disabled() {
        return (
            !this.props.deviceStatus.isOnline ||
            get(this.props.deviceStatus, "state.flags.printing", false)
        );
    }

    render() {
        return (
            <div style={styles.deviceControls}>
                <div style={{ ...styles.xyControls, ...styles.section }}>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("y", 10)}
                    >
                        <FontIcon className="icon-mui-forward3 rotate-270" />
                    </IconButton>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("y", 2)}
                    >
                        <FontIcon className="icon-mui-play3 rotate-270" />
                    </IconButton>
                    <div style={styles.xControls}>
                        <IconButton
                            style={styles.controlBtn}
                            disabled={this.disabled()}
                            onClick={() => this.sendJogCommand("x", -10)}
                        >
                            <FontIcon className="icon-mui-forward3 rotate-180" />
                        </IconButton>
                        <IconButton
                            style={styles.controlBtn}
                            disabled={this.disabled()}
                            onClick={() => this.sendJogCommand("x", -2)}
                        >
                            <FontIcon className="icon-mui-play3 rotate-180" />
                        </IconButton>
                        <IconButton
                            style={styles.controlBtn}
                            disabled={this.disabled()}
                            onClick={() => {
                                this.sendJogCommand("x", "home");
                                this.sendJogCommand("y", "home");
                            }}
                        >
                            <FontIcon className="icon-mui-home3" />
                        </IconButton>
                        <IconButton
                            style={styles.controlBtn}
                            disabled={this.disabled()}
                            onClick={() => this.sendJogCommand("x", 2)}
                        >
                            <FontIcon className="icon-mui-play3" />
                        </IconButton>
                        <IconButton
                            style={styles.controlBtn}
                            disabled={this.disabled()}
                            onClick={() => this.sendJogCommand("x", 10)}
                        >
                            <FontIcon className="icon-mui-forward3" />
                        </IconButton>
                    </div>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("y", -2)}
                    >
                        <FontIcon className="icon-mui-play3 rotate-90" />
                    </IconButton>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("y", -10)}
                    >
                        <FontIcon className="icon-mui-forward3 rotate-90" />
                    </IconButton>
                </div>
                <div style={{ ...styles.zControls, ...styles.section }}>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("z", 10)}
                    >
                        <FontIcon className="icon-mui-forward3 rotate-270" />
                    </IconButton>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("z", 2)}
                    >
                        <FontIcon className="icon-mui-play3 rotate-270" />
                    </IconButton>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("z", "home")}
                    >
                        <FontIcon className="icon-mui-home3" />
                    </IconButton>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("z", -2)}
                    >
                        <FontIcon className="icon-mui-play3 rotate-90" />
                    </IconButton>
                    <IconButton
                        style={styles.controlBtn}
                        disabled={this.disabled()}
                        onClick={() => this.sendJogCommand("z", -10)}
                    >
                        <FontIcon className="icon-mui-forward3 rotate-90" />
                    </IconButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const deviceStatus = state.deviceStatus[ownProps.device.id] || {
        lastPing: moment.unix(0)
    };
    return {
        deviceStatus
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendMessageToDevice: (msg, deviceId) => {
            dispatch(sendMessageToDevice(msg, deviceId));
        }
    };
};
export const DeviceControls = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceControlsComponent);
