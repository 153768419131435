import BugSnag from 'bugsnag-js';

export function initBugSnag(key) {
    if (window.config.BUGSNAG_API_KEY) {
        window.bugsnag = BugSnag(window.config.BUGSNAG_API_KEY);
    }
}

export function logError(msg) {
    console.log(msg);
    if (window.bugsnag) {
        window.bugsnag.notify(new Error(msg));
    }
}
