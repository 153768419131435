import grey from '@material-ui/core/colors/grey';

export default {
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        maxWidth: '664px',
        margin: 'auto',
        padding: '0px 8px',
    },
    subscriptionContainer: {
        margin: '36px auto'
    },
    subscriptionLine: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        padding: '20px',
    },
    subscriptionPrice: {
        fontSize: '36px',
        fontWeight: '700',
    },
    subscriptionSelect: {
        width: '65px',
    },
    subscriptionUnit: {
        fontSize: '20px',
        color: '#888888',
        padding: '0px 12px',
    },
    sectionHeader: {
        fontSize: '20px',
        fontWeight: '700',
        padding: '24px 0px',
    },
    link: {
        textDecoration: 'underline',
    },
    note: {
        padding: '12px',
    },
    pageContainer: {
        margin: '16px',
    },
    formInput: {
        width: '100%',
        marginBottom: '22px',
    },
    button: {
        marginRight: '16px',
    },

    pricingCard: {
        height: '100%',
        display: 'flex',
        flexFlow: 'column'
    },
    pricingHeader: {
        background: grey[200],
        height: '3rem',
        alignItems: 'flex-start',
    },
    price: {
        height: '6.5rem',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '0.5rem',
    },
    pricingAction: {
        marginTop: 'auto',
        padding: '1.5rem',
    },
    featureUl: {
        margin: 0,
        padding: 0,
      },
    featureLi: {
        listStyle: 'none',
    },
}
