import React, { Fragment } from 'react';
import update from 'immutability-helper';

import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Slide from '@material-ui/core/Slide';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import cyan from '@material-ui/core/colors/cyan';

import ScrollDetector from '../common/ScrollDetector';
import PrintJobCard from './PrintJobCard';
import DesertImg from '../../global-assets/images/desert.jpg'

import commonStyles from '../common/styles'

const theme = createMuiTheme({
    palette: {
        primary: cyan,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class PrintJobMainComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            printJobs: [],
            loading: false,
            earliestId: null,
            timelapseWarningOpen: false,
        };
    }

    componentDidMount() {
        fetch('/api/user', {credentials: 'include', redirect: 'error'})
        .then(result => result.json())
        .then(user => {
            if (moment(user.lastVisitedPrintJobs).isBefore(moment().subtract(30, 'd')) && user.Subscriptions.length == 0) {
                this.setState({timelapseWarningOpen: true});
            }
            this.loadData();
        });
    }

    loadData() {
        if (!this.state.loading) {
            this.setState(
                update(this.state, {
                    loading: {
                        $set: true
                    }
                })
            );
            fetch('/api/printJobs?beforeId=' + this.state.earliestId, {credentials: 'include'})
                .then(result => result.json())
                .then(newJobs => {
                    let printJobs = this.state.printJobs.concat(newJobs);
                    this.setState({
                        printJobs,
                        loading: false,
                        earliestId: printJobs.length > 0 ? printJobs[printJobs.length - 1].id : null
                    });
                });
        }
    }

    noPrintJobAlert() {
        if (this.state.printJobs.length === 0 && !this.state.loading) {
            return (
                <div style={{textAlign: 'center', margin: '16px'}}>
                    <img style={{width: 'initial'}} src={DesertImg} />
                    <h2>There is nothing here.
                    </h2>
                </div>
            );
        }
        return (<div />);
    }

    timelapseWarningClosed = () => {
        this.setState({ timelapseWarningOpen: false });
    }

    timelapseWarning() {
        return (
            <ThemeProvider theme={theme}>
                <Dialog
                    open={this.state.timelapseWarningOpen}
                    onClose={this.timelapseWarningClosed}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen={this.props.fullScreen}
                >
                    <DialogTitle id="alert-dialog-title">Welcome back!</DialogTitle>
                    <DialogContent id="alert-dialog-description">
                        <p>It's been more than 30 days since last time you checked out the time-lapses.</p>
                        <p>If "Record Time-lapse" option was previously on, it has been turned off automatically.</p>
                        <p>You can turn it back on if you want OctoPrint Anywhere to automatically record time-lapse for you.</p>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.timelapseWarningClosed} >
                            Gotcha!
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }

    scrollToBottom() {
        this.loadData();
    }

    render() {
        return (
            <div className="card-container">
                {this.timelapseWarning()}
                <div style={{ textAlign: 'right', padding: '0.5em', color: 'gray' }}>Time-lapses missing? Check <a style={commonStyles.link} href="/docs/docs/no-timelapse">the help doc</a> to figure out why.</div>
                <div className="row">
                 {this.state.printJobs.map( (printJob, i) => (
                     <PrintJobCard printJob={printJob} asGuest={false} key={i} />
                 ))}
                </div>
                {this.noPrintJobAlert()}
                <ScrollDetector
                    scrollToBottom={this.loadData.bind(this)} />
                {this.state.loading ? <div className='spinner' /> : ''}
            </div>
        );
    }
}

export default withMobileDialog()(PrintJobMainComponent);
