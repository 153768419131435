import React from "react";
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import cyan from '@material-ui/core/colors/cyan';

import commonStyles from './styles'
import ProBadgeImg from '../../global-assets/images/pro.png';

const theme = createMuiTheme({
    palette: {
        primary: cyan,
    },
});

export function ProBadge() {
    const styles = {
        span: {
            position: 'relative'
        },
        badge: {
            position: 'absolute',
            top: '-3px',
            left: '3px',
            width: '1.5rem',
        }
    }

    return (
        <span style={styles.span}><img style={styles.badge} src={ProBadgeImg}></img></span>
    )
}

export function ProTrialAlert(props) {
    const { isProTrialActive, proTrialStartedAt, Subscriptions } = props.currentUser
    const expiration = moment(proTrialStartedAt).add(15, 'days')
    const days = expiration.diff(moment(), 'days')
    if (Subscriptions.length > 0 ) {
        return null
    }

    if (isProTrialActive && days < 5) {
        return (
            <div style={commonStyles.notifBar}>
                <div style={{padding: '0px 12px'}}>Your PRO free trial will expire {expiration.fromNow()}. <a href='/app/subscription' style={commonStyles.notifBarLink}>Upgrade now</a></div>
            </div >
        )
    }

    return null
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ProGatedComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dlgOpen: false
        };
    }

    clicked = () => {
        if (!this.props.hasPro) {
            this.setState({ dlgOpen: true });
            gtag('event', 'view', {
                'event_category': 'pro_gated',
            });
        } else {
            this.props.onClick();
        }
    }

    dlgClosed = () => {
        this.setState({ dlgOpen: false });
    }

    render() {
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                onClick: this.clicked,
            });
        });

        return (
            <ThemeProvider theme={theme}>
                {children}
                <Dialog
                    open={this.state.dlgOpen}
                    onClose={this.dlgClosed}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: 3000}}
                    fullScreen={this.props.fullScreen}
                >
                    <DialogTitle id="alert-dialog-title">Interested in PRO features?</DialogTitle>
                    <DialogContent id="alert-dialog-description">
                        Support OctoPrint Anywhere by upgrading to the PRO plan. With the PRO plan you get:
                        <ul>
                            <li style={{padding: '4px'}}>Smooth 25 FPS webcam streaming. <a href="https://www.youtube.com/watch?v=ZaGivkStKqs" style={commonStyles.link}>No more jerky webcam feed!</a></li>
                            <li style={{padding: '4px'}}>Uploading G-code to your printer from OctoPrint Anywhere and starting a print remotely.</li>
                            <li style={{padding: '4px'}}>Sharing your webcam feed and time-lapses with just a simple click.</li>
                            <li style={{padding: '4px'}}>Learn more about why subscription is required for PRO plan at <a href='https://www.getanywhere.io/assets/oa10.html' style={commonStyles.link}> OctoPrint Anywhere 1.0 announcment page</a>.</li>
                        </ul>
                        <br />
                        <p>Do you want to try PRO plan for 1 month completely free?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.dlgClosed} >
                            No, Thanks.
                        </Button>
                        <Button onClick={() => window.location.href = '/app/subscription'} color="primary" autoFocus>
                            Sure! Check It Out!
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }
}

export let ProGated
ProGated = withMobileDialog()(ProGatedComponent)
