import React from 'react'
import Checkbox from 'material-ui/Checkbox'
import RaisedButton from 'material-ui/RaisedButton'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import styles from './styles'
import commonStyles from '../common/styles'
export default class Consent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consented: false
        };
    }

    consentChanged(isInputChecked: boolean) {
        this.setState({consented: isInputChecked})
    }

    render() {
        const label = (
            <span>
                I have read and agree to the&nbsp;
                <a
                    href="/docs/docs/terms"
                    target="_blank"
                    style={styles.link}
                >
                    Terms of Use
                </a> and the&nbsp;
                <a
                    href="/docs/docs/privacy"
                    target="_blank"
                    style={styles.link}
                >
                   Privacy Policy
                </a>
            </span>
        )
        const anchorStyle = {
            position: 'relative',
            zIndex: 3,
        }
        return (
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5}
                    justify="center"
                    alignItems="center"
                    >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <br /><br />
                     <p style={{fontWeight: 'bolder'}}>Important: OctoPrint Anywhere has been succeeded by <a
                                href="https://www.thespaghettidetective.com/"
                                style={commonStyles.link}
                            >The Spaghetti Detective</a>.</p>
                    <p>However, if The Spaghetti Detective is not your cup of tea, you can press "CONTINUE" to start 15-day free trial.</p>
                    <p>At the end of the 15-day free trial, you will need to upgrade to OctoPrint Anywhere PRO/PRO+ plan to keep enjoying OctoPrint Anywhere service.</p>
                    <br />
                    <Checkbox labelStyle={anchorStyle} label={label} checked={this.state.consented} onCheck={(_, checked) => this.consentChanged(checked)}/>
                    <br />
                    <RaisedButton
                            label="CONTINUE"
                            disabled={!this.state.consented}
                            fullWidth={true}
                            primary={true}
                            href="/app/consented"
                        />
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
