import React, { Component } from "react";
import { connect } from "react-redux";
import get from 'lodash/get';
import RaisedButton from 'material-ui/RaisedButton'

import DeviceCardStub from '../../global-assets/images/device-card-stub.png'

const styles = {
    text: {
        color: "#dddddd", margin: "auto", padding: "1.5rem"
    },
}
class NeedToSubscribeComponent extends Component {
    render() {
        gtag('event', 'view', {
            'event_category': 'need_to_subscribe',
        });
        const requiredPlanName = get(this.props, ['devices'], []).length > 2 ? 'PRO+' : 'PRO'

        let msg = null
        const planName = get(this.props, ['currentUser', 'currentSubscription']) ? 'PRO' : 'FREE'
        let btnLabel = 'Check Out Plan Pricing'
        if (planName == 'PRO') {
            msg = 'You can only connect up to 2 printers because you are currently on the PRO plan.'
        } else {
            if (get(this.props, ['currentUser', 'isProTrialActive'])) {
                msg = 'You can only connect up to 2 printers because you are currently on the 1-month free trial.'
            } else {
                msg = 'You can only connect up to 1 printer because you are currently on the FREE plan.'
                btnLabel = 'Get 1-month free trial'
            }
        }
        const maxPrinters = planName == 'FREE' ? '1 printer' : '2 printers'

        return (
            <div style={{ position: "relative" }}>
                <img src={DeviceCardStub} />
                <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, display: "flex", flexFlow: "column nowrap" }}>
                <div style={styles.text}>
                        <p>{msg}</p>
                        <p>You can delete extra printers so that this printer can connect.</p>
                        <p>Or you can upgrade to the {requiredPlanName} plan to connect all your printers.</p>
                    </div>
                    <RaisedButton
                        primary={true}
                        style={{ margin: "auto" }}
                        label={btnLabel}
                        href="/app/subscription"
                    />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.currentUser,
        devices: state.devices,
    };
};

export default connect(
    mapStateToProps,
    null,
)(NeedToSubscribeComponent);
