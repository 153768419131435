import cyan from '@material-ui/core/colors/cyan';

export default {
    outterContainer: {
        padding: "0px",
    },

    detailPanel: {
        padding: "16px 12px 16px 48px",
    },

    detailPanelTitle: {
        fontWeight: "bolder",
        fontSize: "1.1em",
    },

    cellStyle: {
        padding: '16px 16px 16px 0px',
        fontSize: '0.9rem',
    },

    detailPanelFieldName: {
        color: "rgba(0, 0, 0, 0.3)",
    },

    detailPanelActionBtn: {
        margin: "0px 12px 0px 12px",
    },

    fabBtnInit: {
        position: "fixed",
        right: "32px",
        bottom: "-64px",
        zIndex: "1000",
        transition: "all 0.2s ease-in-out",
    },

    fabBtn: {
        bottom: "64px",
    },

    dropzone: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        margin: "18px",
        minHeight: "120px",
        borderWidth: "2px",
        borderRadius: "2px",
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    },

    dropzonActive: {
        backgroundColor: cyan[100],
    },

    dropIcon: {
        fontSize: "48px",
        margin: "24px 0px",
    },

    uploadFilename: {
        display: "flex",
        justifyContent: "space-between",
    },

    printDialogPrinterName: {
        fontSize: '1.35em',
        fontWeight: 'bolder',
    },

    printDialogPrinterStatus: {
        width: "100%",
    }
};
