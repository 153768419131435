import React from 'react';
import queryString from 'query-string';
import RaisedButton from 'material-ui/RaisedButton';

export default class extends React.Component {

    render() {
        if (!queryString.parse(this.props.location.search).token) {
            return (
                <div>You need to click the link on the OctoPrint Anywhere settings page to register your 3D printer.</div>
            );
        }

        return (
          <div className='container'>
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='logout-paper col-xs-8 col-sm-6 col-md-4 col-lg-4 center'>
                </div>
            </div>
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='logout-paper col-xs-12 col-sm-12 col-md-8 col-lg-8 center'>
                    <h3>To link your 3D printer to OctoPrint Anywhere, please:</h3>
                    <RaisedButton label="Log In or Sign Up" primary={true} onClick={() => window.location.href = "/pub/auth0_login"} />
                </div>
            </div>
          </div>
        );
    }
}
