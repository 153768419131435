import React from "react";
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from 'lodash/get'
import moment from 'moment';
import FontIcon from "material-ui/FontIcon";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import {
    Toolbar,
    ToolbarGroup,
} from "material-ui/Toolbar";

import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import {
    DASHBOARD_CARD_SORTING,
    saveLocalStorageItem,
    getLocalStorageItem
} from "../../utils/browserLocalStorage";
import { DeviceCard } from "./DeviceCard";
import { fetchDevices, fetchUser } from "../../actions";

import commonStyles from "../common/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const SORT_BY_LAST_ACTIVE = "SORT_BY_LAST_ACTIVE";
const SORT_BY_NAME = "SORT_BY_NAME";

const Toggle = withStyles({
    switchBase: {
        color: cyan[500],
        '&$checked': {
            color: cyan[500],
        },
        '&$checked + $track': {
            backgroundColor: cyan[500],
        },
    },
    checked: {},
    track: { backgroundColor: cyan[500] },
})(Switch);

class DevicesMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trialDlgOpen: false,
        }
    }

    componentDidMount() {
        this.props.fetchDevices();
    }

    trialDlgClosed = () => {
        this.setState({ trialDlgOpen: false });
    }

    noDeviceAlert() {
        if (this.props.devices.length === 0) {
            return (
                <div style={{ textAlign: "center", margin: "16px" }}>
                    <h2>
                        <p>Hmmmm... I'm not seeing any 3D printer here.</p>
                        <p>
                            How about &nbsp;<a
                                href="/docs/docs/no-printer"
                                style={commonStyles.link}
                            >
                                following these steps and getting your 3D
                                printers back?
                            </a>
                        </p>
                    </h2>
                </div>
            );
        }
        return <div />;
    }

    sortingChanged = event => {
        const sorting = event.target.checked ? SORT_BY_LAST_ACTIVE : SORT_BY_NAME;
        saveLocalStorageItem(DASHBOARD_CARD_SORTING, sorting);
        window.location.reload();
    };

    sorting() {
        return getLocalStorageItem(
            DASHBOARD_CARD_SORTING,
            SORT_BY_LAST_ACTIVE
        )
    }

    render() {
        if (this.props.loading) {
            return <div className="spinner" />;
        }

        const {currentUser} = this.props;
        if (currentUser && !currentUser.hasPro && !currentUser.isProTrialActive) {
            fetch('/api/user/startProTrial', {
                credentials: 'include',
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                redirect: 'error'
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return Promise.reject(new Error(response.statusText));
                    }
                })
                .then(() => {
                    this.props.fetchUser();
                    this.setState({ trialDlgOpen: true });
                })
        }

        const sortedDevices = this.props.devices.sort((a, b) => {
            if (this.sorting() === SORT_BY_LAST_ACTIVE) {
                return moment(b.lastSeen ? b.lastSeen : 0) - moment(a.lastSeen ? a.lastSeen : 0);
            } else {
                return a.name.localeCompare(b.name);
            }
        });

        return (
            <div>
                {typeof Storage !== "undefined" && (
                    <Toolbar>
                        <ToolbarGroup>
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Sort By Name</Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Toggle
                                                checked={this.sorting() === SORT_BY_LAST_ACTIVE}
                                                onChange={this.sortingChanged}
                                                color="primary"
                                            />
                                        }
                                        label="Sort By Last Active"
                                    />
                                </Grid>
                            </Grid>     </ToolbarGroup>
                    </Toolbar>
                )}
                <div className="card-container">
                    <div className="row around-xs">
                        {sortedDevices.map((device, i) => (
                            <DeviceCard device={device} key={i} />
                        ))}
                    </div>
                    {this.noDeviceAlert()}
                </div>
                {this.props.currentUser && <Dialog
                    fullScreen={this.props.fullScreen}
                    open={this.state.trialDlgOpen}
                    onClose={this.trialDlgClosed}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Your free trial has started!</DialogTitle>
                    <DialogContent id="alert-dialog-description">
                        <p>You can now connect up to 2 printers and enjoy all the PRO features.</p>
                        <p>Your free trial will expire on {moment(this.props.currentUser.proTrialStartedAt).add(15, 'days').format("dddd, MMMM Do YYYY")}. At that point, if you want to keep the access to your OctoPrint Anywhere account, you will need to upgrade to one of the PRO plans.</p>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.trialDlgClosed} >
                            Gotcha!
                    </Button>
                    </DialogActions>
                </Dialog>}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.currentUser,
        devices: state.devices,
        loading: get(state, 'loading.DEVICE_LIST_FETCH', false),
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUser,
            fetchDevices,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(DevicesMainComponent));
