import get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import FontIcon from "material-ui/FontIcon";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import Toggle from "material-ui/Toggle";
import SelectField from "material-ui/SelectField";
import { Divider } from "material-ui";

import * as colors from 'material-ui/styles/colors'

import { updateDevices, deleteDevice } from "../../actions";
import GuestAccessDialog from "../common/GuestAccessDialog";
import OctoPrintImg from '../../global-assets/images/octoprint.png'

import styles from "./styles";
import commonStyles from '../common/styles'

class CardMenuComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: false,
            ipAddrDlgOpen: false,
            device: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.device) {
            const device = (({ id, name, flipH, flipV, rotate90, rotate90N, recordTimelapse, octolapseOptedIn, camResolution }) => ({ id, name, flipH, flipV, rotate90, rotate90N, recordTimelapse, octolapseOptedIn, camResolution }))(nextProps.device);
            return { device };
        } else {
            return null;
        }
    }

    deleteMenuTapped() {
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: true,
            sharingDlgOpen: false
        });
    }

    cancelDelete() {
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: false
        });
    }

    confirmDelete() {
        this.props.deleteDevice(this.props.device.id);
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: false
        });
    }

    prefMenuTapped() {
        this.setState({
            preferencesOpen: true,
            deleteDlgOpen: false,
            sharingDlgOpen: false
        });
    }

    cancelChange() {
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: false,
            device: null,
        });
    }

    sharingMenuTapped() {
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: true
        });
    }

    sharingDismissed() {
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: false
        });
    }

    confirmChange() {
        this.props.updateDevices([this.state.device]);
        this.setState({
            preferencesOpen: false,
            deleteDlgOpen: false,
            sharingDlgOpen: false,
            device: null,
        });
    }

    ipAddrMenuTapped() {
        this.setState({
            ipAddrDlgOpen: true
        });
    }

    closeIpAddrDlg() {
        this.setState({
            ipAddrDlgOpen: false
        });
    }

    ipAddrDialog() {
        const btns = [
            <FlatButton
                label="OK"
                primary={true}
                onClick={() => this.closeIpAddrDlg()}
            />
        ];
        const ipAddrs = get(this.props.deviceStatus, "ipAddrs", []);
        return (
            <Dialog
                title="OctoPrint's IP Address"
                modal={false}
                actions={btns}
                autoDetectWindowHeight={true}
                autoScrollBodyContent={true}
                repositionOnUpdate={true}
                open={this.state.ipAddrDlgOpen}
                onRequestClose={() => this.closeIpAddrDlg()}
            >
                {ipAddrs.length === 0
                    ? "Working hard to get the IP Address... Stay put."
                    : ipAddrs.map((ip, i) => <p key={i}>{ip}</p>)}
            </Dialog>
        );
    }

    onFieldChanged(fieldName, value) {
        this.setState({
            device:
                update(
                    this.state.device, {
                    [fieldName]: { $set: value },
                })
        });
    }

    webcamSettingsChanged() {
        return this.props.device.flipH != this.state.device.flipH ||
            this.props.device.flipV != this.state.device.flipV ||
            this.props.device.rotate90 != this.state.device.rotate90 ||
            this.props.device.rotate90N != this.state.device.rotate90N ||
            this.props.device.camResolution != this.state.device.camResolution
    }

    textForWebcamResolution(resolution) {
        return {
            low: "Low - 320x240",
            medium: "Medium - 640x480",
            high: "High - 1296x972",
            high_16_9: "High (16:9) - 1280x720",
            ultrahigh_16_9: "Ultra high (16:9) - 1920x1080",
        }[resolution];
    }

    explanationForWebcamResolution(resolution) {
        return {
            low: "Use it only in poor network condition, and the video stream freezes a lot.",
            medium: "A good balance between quality and network bandwidth. Don't change it if you are not sure.",
            high: "Very demanding on network bandwidth. Make sure you have solid internet connection.",
            high_16_9: "Very demanding on network bandwidth. Make sure you have solid internet connection. Partial Field of View because of the 16:9 ratio.",
            ultrahigh_16_9: "Is your Pi connected by ethernet? 😜 Partial Field of View because of the 16:9 ratio."
        }[resolution];
    }

    render() {
        const ipAddrs = get(this.props.deviceStatus, "ipAddrs", []);
        let port = get(this.props.deviceStatus, "port");
        port = (port == 5000) ? '' : ':' + port;
        const octoprintLink = (ipAddrs.length > 0 && port != undefined) ? `http://${ipAddrs[0]}${port}` : undefined;

        const deleteDlgBtns = [
            <FlatButton
                label="No"
                primary={true}
                keyboardFocused={true}
                onClick={() => this.cancelDelete()}
            />,
            <FlatButton
                label="Yes"
                primary={false}
                onClick={() => this.confirmDelete()}
            />
        ];
        const prefDlgBtns = [
            <FlatButton
                label="Cancel"
                primary={true}
                keyboardFocused={true}
                onClick={() => this.cancelChange()}
            />,
            <FlatButton
                label="Confirm"
                primary={false}
                onClick={() => this.confirmChange()}
            />
        ];
        return (
            <div>
                <IconMenu
                    className="card-menu"
                    iconButtonElement={
                        <IconButton>
                            <FontIcon className="icon-mui-more_vert" />
                        </IconButton>
                    }
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    targetOrigin={{ horizontal: "right", vertical: "top" }}
                >
                    {octoprintLink && (<MenuItem>
                        <div style={styles.octoprintMenu}><a href={octoprintLink} ><img src={OctoPrintImg} style={styles.octoprintMenuIcon} />Goto {octoprintLink}</a></div>
                    </MenuItem>)}
                    {octoprintLink && <Divider />}
                    <MenuItem
                        primaryText="Preferences"
                        disabled={!this.props.device.subscription}
                        onClick={() => this.prefMenuTapped()}
                        leftIcon={<FontIcon className="icon-mui-settings" />}
                    />
                    <MenuItem
                        primaryText='Sharing'
                        onClick={() => this.sharingMenuTapped()}
                        leftIcon={<FontIcon className="icon-mui-share3" />}
                    />
                    <MenuItem
                        primaryText="IP Address"
                        disabled={!this.props.device.subscription}
                        onClick={() => this.ipAddrMenuTapped()}
                        leftIcon={<FontIcon className="icon-mui-router" />}
                    />
                    <MenuItem
                        primaryText="Delete"
                        style={{ color: colors.red500 }}
                        onClick={() => this.deleteMenuTapped()}
                        leftIcon={<FontIcon className="icon-mui-delete" style={{ color: colors.red500 }} />}
                    />
                </IconMenu>
                <Dialog
                    title="Are you sure?"
                    actions={deleteDlgBtns}
                    modal={false}
                    open={Boolean(this.state.deleteDlgOpen)}
                    onRequestClose={() => this.cancelDelete()}
                >
                    Do you really want to delete {this.props.device.name} ?
                </Dialog>
                <Dialog
                    title="Preferences"
                    actions={prefDlgBtns}
                    modal={false}
                    autoDetectWindowHeight={true}
                    autoScrollBodyContent={true}
                    repositionOnUpdate={true}
                    open={this.state.preferencesOpen}
                    onRequestClose={() => this.cancelChange()}
                >
                    <div className="settings-section">
                        <TextField
                            floatingLabelText="How'd you call your 3D printer?"
                            value={this.state.device.name}
                            fullWidth={true}
                            onChange={(event, value) => this.onFieldChanged('name', value)}
                        />
                    </div>
                    {this.props.device.premium_video && <div className="settings-section">
                        <div className="field-label">Webcam resolution (premium feature)</div>
                        <div>
                            <SelectField
                                fullWidth={true}
                                value={this.state.device.camResolution}
                                onChange={(event, index, value) => this.onFieldChanged('camResolution', value)}
                                errorText={this.explanationForWebcamResolution(this.state.device.camResolution)}
                                errorStyle={{ color: 'rgba(0, 0, 0, 0.3)', fontSize: '0.9em', lineHeight: '18px' }}
                            >
                                {["low", "medium", "high", "high_16_9", "ultrahigh_16_9"].map((res, i) => <MenuItem value={res} primaryText={this.textForWebcamResolution(res)} key={i} />)}
                            </SelectField>
                        </div>
                    </div>}
                    <div className="settings-section">
                        <div className="field-label">Webcam needs fixing?</div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <Checkbox
                                    label="Flip Horizontally"
                                    checked={this.state.device.flipH}
                                    onCheck={(event, value) => this.onFieldChanged('flipH', value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <Checkbox
                                    label="Flip Vertically"
                                    checked={this.state.device.flipV}
                                    onCheck={(event, value) => this.onFieldChanged('flipV', value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <Checkbox
                                    label="Rotate 90&deg; CCW"
                                    checked={this.state.device.rotate90}
                                    onCheck={(event, value) => this.onFieldChanged('rotate90', value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <Checkbox
                                    label="Rotate 90&deg; CW"
                                    checked={this.state.device.rotate90N}
                                    onCheck={(event, value) => this.onFieldChanged('rotate90N', value)}
                                />
                            </div>

                            {this.props.device.premium_video && (<div className="col-xs-12" style={{ paddingTop: '0.5em' }}>
                                Orientation not working as expected? Check <a style={commonStyles.link} href="/docs/docs/pro-camera-settings">this help document</a>.
                            </div>)}
                        </div>

                        {this.webcamSettingsChanged() && this.props.device.premium_video && (
                            <div className="col-xs-12" style={styles.orientationWarning}>
                                Webcam settings change won't take effect until OctoPrint restarts next time.
                            </div>
                        )}
                    </div>
                    <div className="settings-section">
                        <div className="field-label">
                            Record timelapse for you?
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <Toggle
                                    toggled={
                                        this.state.device.recordTimelapse
                                    }
                                    disabled={!get(this.props, 'currentUser.hasPro', false)}
                                    onToggle={(event, value) => this.onFieldChanged('recordTimelapse', value)}
                                />
                            </div>
                        </div>
                        <div className="field-label">
                            Upload Octolapse time-lapses?
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <Toggle
                                    toggled={
                                        this.state.device.octolapseOptedIn
                                    }
                                    onToggle={(event, value) => this.onFieldChanged('octolapseOptedIn', value)}
                                />
                            </div>
                        </div>
                        {this.state.device.octolapseOptedIn && !this.props.device.octolapseOptedIn && (
                            <div className="col-xs-12" style={styles.orientationWarning}>
                                Please make sure you have <a href="https://plugins.octoprint.org/plugins/octolapse/">Octolapse</a> installed and configured correctly. Otherwise you won't have time-lapses in OctoPrint Anywhere.
                            </div>
                        )}
                        <div style={{ paddingTop: '0.5em' }}>
                            Confused about time-lapse settings? <a style={commonStyles.link} href="/docs/docs/no-timelapse">Get some help!</a>
                        </div>
                    </div>
                </Dialog>
                {this.ipAddrDialog()}
                {this.state.sharingDlgOpen ? (
                    <GuestAccessDialog
                        endpoint={`/api/devices/${
                            this.props.device.id
                            }/guests/`}
                        publicPathPrefix={`${window.location.protocol}//${
                            window.location.host
                            }/pub/guest/`}
                        title="Webcam Stream Sharing"
                        onDismiss={this.sharingDismissed.bind(this)}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.currentUser,
        deviceStatus: state.deviceStatus[ownProps.device.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateDevices: devices => {
            dispatch(updateDevices(devices));
        },
        deleteDevice: deviceId => {
            dispatch(deleteDevice(deviceId));
        }
    };
};
export const CardMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardMenuComponent);
