import React from "react";
import moment from "moment";
import download from "downloadjs";
import { Card, CardText } from "material-ui/Card";

import CardMenu from "./CardMenu";
import VideoPlayer from "../common/VideoPlayer";

export default class extends React.Component {
    cardHeader() {
        const startedAt = moment(this.props.printJob.startedAt);
        const dateStr = startedAt.isAfter(moment().subtract(7, "days"))
            ? startedAt.fromNow()
            : startedAt.format("MMMM Do YYYY");

        if (this.props.asGuest) {
            return (
                <CardText className="card-header">
                    <div className="card-title">
                        <span className="title-text">Printed {dateStr}</span>
                    </div>
                </CardText>
            );
        } else {
            return (
                <CardText className="card-header">
                    <div className="card-title">
                        <span className="title-text">
                            {this.props.printJob.gcodeName} ({dateStr})
                        </span>
                        <span className="subtitle-text">
                            {this.props.printJob.Device.name}
                        </span>
                    </div>
                    <CardMenu delegate={this} printJob={this.props.printJob} />
                </CardText>
            );
        }
    }

    printJobDeleted() {
        fetch("/api/printJobs/" + this.props.printJob.id, {
            credentials: "include",
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        }).then(() => {
            window.location.reload();
        });
    }

    downloadTimelapse = () => {
        fetch(this.props.printJob.timelapseUrl)
            .then(response => response.blob())
            .then(data => {
                let videoName = this.props.printJob.gcodeName;
                if (!videoName.endsWith(".mp4")) {
                    videoName += ".mp4";
                }
                download(data, videoName, "video/mp4");
            });
    }

    render() {
        const videoJsOptions = {
            autoplay: false,
            controls: true,
            fluid: true,
            muted: true,
            poster: this.props.printJob.posterUrl,
            sources: [{
                src: this.props.printJob.timelapseUrl,
                type: "video/mp4",
           }],
        };
        return (
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                <Card className="timelapse-card">
                    {this.cardHeader()}
                    <CardText >
                        <VideoPlayer {...videoJsOptions} />
                    </CardText>
                </Card>
            </div>
        );
    }
}
