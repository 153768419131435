import moment from "moment";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import has from "lodash/has";
import filter from "lodash/filter";
import update from "immutability-helper";
import ifvisible from "ifvisible.js";
require("moment-duration-format");
import {
    Card,
    CardActions,
    CardMedia,
    CardTitle,
    CardText
} from "material-ui/Card";
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from "material-ui/Table";
import { Tabs, Tab } from "material-ui/Tabs";
import Avatar from "material-ui/Avatar";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import IconButton from 'material-ui/IconButton';
import FontIcon from "material-ui/FontIcon";
import Popover from "material-ui/Popover";
import Dialog from "material-ui/Dialog";
import LinearProgress from "material-ui/LinearProgress";

import * as colors from 'material-ui/styles/colors'

import VideoPlayer from "../common/VideoPlayer";
import {
    subscribeToDevice,
    unsubscribeFromDevice,
    sendMessageToDevice,
} from "../../actions";
import { CardMenu } from "./CardMenu";
import { TemperatureDialog } from "./TemperatureDialog";
import { DeviceControls } from "./DeviceControls";
import NeedToSubscribe from "./NeedToSubscribe";
import { ChromeBugAlertComponent } from "../common/ChromeBugAlert";

import {
    WEBCAM_FEED_WARNING_DISMISSED,
    saveLocalStorageItem,
    getLocalStorageItem
} from "../../utils/browserLocalStorage";

import AvatarDefault from "../../global-assets/images/avatar_default.png";
import CameraStub from "../../global-assets/images/camera.png";

import commonStyles from "../common/styles";
import styles from "./styles";

class DeviceCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cmdToConfirm: null,
            temperatureToChange: null,
            statusHelpMsg: null,
            showWebcamFeedWarning: false,
        };
        this.currentImgUrl = props.device.lastImgUrl || CameraStub;
        this.imgLoading = props.device.lastImgUrl !== null;
        this.lastImageLoaded = Date.now();
    }

    componentDidMount() {
        this.props.subscribeToDevice(this.props.device.id);

        ifvisible.on("focus", () => {
            this.props.subscribeToDevice(this.props.device.id);
        });

        ifvisible.on("blur", () => {
            this.props.unsubscribeFromDevice(this.props.device.id);
        });

        setTimeout(() => {
            this.setState({showWebcamFeedWarning: moment(parseInt(getLocalStorageItem(WEBCAM_FEED_WARNING_DISMISSED, "0"))).isBefore(moment().subtract(14, 'd')) && !this.props.currentUser.hasPro});
        }, 10*1000)
    }

    componentWillUnmount() {
        this.props.unsubscribeFromDevice(this.props.device.id);
    }

    onImgDoneLoading() {
        this.imgLoading = false;
        this.lastImageLoaded = Date.now();
    }

    lastSeen() {
        return this.props.device.lastSeen
            ? moment(this.props.device.lastSeen).fromNow()
            : "Never";
    }

    jobInProgress() {
        return (
            (get(this.props.deviceStatus, "state.flags.printing") ||
                get(this.props.deviceStatus, "state.flags.paused")) &&
            get(this.props.deviceStatus, "job.file.name")
        );
    }

    temperatureRow(key, temp) {
        function tempStr(temp) {
            if (temp <= 1) {
                // For some reason OctoPrint will send 1 when it's actually off
                return "OFF";
            }
            return temp.toFixed(1) + "°C";
        }

        function heaterName(key) {
            return key == "bed" ? "BED" : "E #" + key.slice(-1);
        }

        const extraClasses = this.props.deviceStatus.isOnline
            ? "active"
            : "inactive";
        return (
            <TableRow displayBorder={false} key={key}>
                <TableRowColumn>{heaterName(key)}:</TableRowColumn>
                <TableRowColumn className={extraClasses}>
                    {tempStr(temp.actual)}
                </TableRowColumn>
                <TableRowColumn className={extraClasses}>
                    <FlatButton
                        label={tempStr(temp.target)}
                        labelPosition="before"
                        disabled={!this.props.deviceStatus.isOnline}
                        labelStyle={styles.temperature}
                        onClick={() => this.temperatureEditBtnTapped(key)}
                        icon={<FontIcon className="icon-mui-mode_edit" />}
                    />
                </TableRowColumn>
            </TableRow>
        );
    }

    temperatureEditBtnTapped(key) {
        this.setState({
            temperatureToChange: key
        });
    }

    temperatureFeed() {
        if (!this.props.isOnline && !get(this.props.deviceStatus, "state.flags.operational", false)) {
            return <div />;
        }
        if (Object.keys(get(this.props, 'deviceStatus.temps', {})).length === 0) {
            return (
                <div style={styles.section}>
                    <div className="spinner" style={{ margin: '28px auto' }} />
                    <p style={{ textAlign: 'center' }} >Waiting for printer status...</p>
                </div>
            );
        }

        const tempKeys = filter(
            ["tool0", "tool1", "bed"],
            key => key in this.props.deviceStatus.temps
        );

        return (
            <div style={styles.section}>
                <Table selectable={false} className="tempfeed">
                    <TableHeader
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                    >
                        <TableRow>
                            <TableHeaderColumn />
                            <TableHeaderColumn>ACTUAL</TableHeaderColumn>
                            <TableHeaderColumn>TARGET</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {tempKeys.map(key =>
                            this.temperatureRow(
                                key,
                                this.props.deviceStatus.temps[key]
                            )
                        )}
                    </TableBody>
                </Table>
                {this.state.temperatureToChange && (
                    <TemperatureDialog
                        presets={get(
                            this.props,
                            "deviceStatus.settings.temperature.profiles",
                            []
                        )}
                        temperatureToChange={this.state.temperatureToChange}
                        current={this.props.deviceStatus.temps}
                        confirmed={newTemp =>
                            this.confirmTemperatureChange(newTemp)
                        }
                        cancelled={() => this.cancelTemperatureChange()}
                    />
                )}
            </div>
        );
    }

    confirmTemperatureChange(newTemperature) {
        this.sendCmd({
            temps: {
                set: {
                    target: newTemperature,
                    heater: this.state.temperatureToChange
                }
            }
        });
        this.setState({ temperatureToChange: null });
    }

    cancelTemperatureChange() {
        this.setState({ temperatureToChange: null });
    }

    openDeviceStatusHelp = (event, statusHelpMsg) => {
        // This prevents ghost click.
        event.preventDefault();
        this.setState({
            statusHelpMsg,
            anchorEl: event.currentTarget
        });
    };
    closeDeviceStatusHelp = () => {
        this.setState({
            statusHelpMsg: null,
        });
    };

    formattedPrintTime(printTime) {
        const d = moment.duration(printTime, "seconds")
        const h = Math.floor(d.asHours());
        const m = d.minutes();
        return (<div className='formattedTime'>{h}<span className='unit'>h </span>{m}<span className='unit'>m</span></div>)
    }

    /* helpers to create React elements */
    jobFeed() {
        if (!this.props.deviceStatus.isOnline || !this.jobInProgress()) {
            return <div />;
        }

        const printTime = get(this.props.deviceStatus, "progress.printTime");
        const printTimeLeft = get(
            this.props.deviceStatus,
            "progress.printTimeLeft"
        );

        return (
            <div style={styles.section}>
                <Table selectable={false} className="jobfeed">
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                        <TableRow>
                            <TableHeaderColumn colSpan="2">
                                {this.props.deviceStatus.job.file.name}
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        <TableRow displayBorder={false}>
                            <TableRowColumn>Print Time:</TableRowColumn>
                            <TableRowColumn>
                                {typeof printTime === "undefined" ||
                                    printTime === null
                                    ? "N/A"
                                    : this.formattedPrintTime(printTime)}
                            </TableRowColumn>
                        </TableRow>
                        <TableRow displayBorder={false}>
                            <TableRowColumn>Print Time Left:</TableRowColumn>
                            <TableRowColumn>
                                {typeof printTimeLeft === "undefined" ||
                                    printTimeLeft === null
                                    ? "N/A"
                                    : this.formattedPrintTime(printTimeLeft)}
                            </TableRowColumn>
                        </TableRow>
                    </TableBody>
                </Table>
                {this.actionButtons()}
            </div>
        );
    }

    hideWebcamFeedWarning = () => {
        saveLocalStorageItem(WEBCAM_FEED_WARNING_DISMISSED, moment().valueOf());
        this.setState({showWebcamFeedWarning: false});
    }

    webcamFeed(jpgOnly) {
        if (get(this.props, "deviceStatus.hlsAvailable", false) && !jpgOnly) {
            const is169 = Boolean(this.props.device.camResolution.match(/16_9/));
            const isRotated = Boolean(this.props.device.rotate90 ^ this.props.device.rotate90N);
            let aspectRatio;
            switch (`${is169} ${isRotated}`) {
                case 'false false': aspectRatio = '4:3'; break;
                case 'true false': aspectRatio = '16:9'; break;
                case 'false true': aspectRatio = '3:4'; break;
                case 'true true': aspectRatio = '9:16'; break;
            }

            const videoJsOptions = {
                autoplay: true,
                muted: true,
                controls: true,
                fluid: true,
                aspectRatio,
                sources: [{
                    src: `/video/${this.props.device.token}/livestream.m3u8`,
                    type: 'application/x-mpegURL',
                }],
            }

            return (<div>
                <VideoPlayer {...videoJsOptions} />
                { /Android/.test(navigator.userAgent)
                    && /Chrome/.test(navigator.userAgent)
                    && /Google Inc/.test(navigator.vendor)
                    && <ChromeBugAlertComponent />
                }
            </div>)
        }

        let outterClassName = "";
        let innerClassName = "";
        if (this.currentImgUrl !== CameraStub && !get(this.props, "deviceStatus.hlsAvailable", false)) {
            if (this.props.device.rotate90) {
                outterClassName += " webcam_rotated";
            }
            if (this.props.device.rotate90N) {
                outterClassName += " webcam_rotated_n";
            }
            if (this.props.device.flipV) {
                innerClassName += " flipV";
            }
            if (this.props.device.flipH) {
                innerClassName += " flipH";
            }
            if (!this.props.deviceStatus.isOnline) {
                innerClassName += " dimmed";
            }
        }

        if (!this.imgLoading && has(this.props, "deviceStatus.stream.imgUrl")) {
                this.imgLoading =
                    this.props.deviceStatus.stream.imgUrl != this.currentImgUrl;
                this.currentImgUrl = this.props.deviceStatus.stream.imgUrl;
        }

        let overlayStyle = null;
        let overlayContent

        if (this.props.deviceStatus.isOnline && this.currentImgUrl !== CameraStub) {
            if (this.state.showWebcamFeedWarning) {
            overlayContent = (
                <Fragment>
                    <div style={{ padding: '0px 12px', flexGrow: '1' }}>Slow webcam feed? <a href='/app/subscription' style={commonStyles.notifBarLink}>Try PRO for free to get 25 FPS streaming.</a><a href='https://www.getanywhere.io/assets/oa10.html#fullstory' style={commonStyles.notifBarLink}>Why?</a></div>
                    <IconButton onClick={this.hideWebcamFeedWarning}><FontIcon className='icon-mui-close' /></IconButton>
                </Fragment >
                );
            overlayStyle = commonStyles.notifBar;
            } else {
                overlayContent = null;
            }
        } else {
            if (Date.now() - this.lastImageLoaded > 15 * 1000) {
                overlayContent = (
                    <CardTitle title={<div>Waiting for camera feed...<div style={{fontSize: '0.8em'}}><a style={commonStyles.link} href="/docs/docs/no-webcam-streaming">Not getting the feed?</a></div></div>} >
                        </CardTitle>)
            } else {
            overlayContent = (
            <CardTitle title={<div>Waiting for camera feed...</div>} >
                </CardTitle>)
            }
        }
        return (
            <CardMedia
                overlay={overlayContent}
                overlayContentStyle={overlayStyle}
                style={styles.cardBody}
            >
                <div id="webcam_rotator" className={outterClassName}>
                    <div className="webcam_fixed_ratio ratio43">
                        <div className="webcam_fixed_ratio_inner">
                            <img
                                id="webcam_image"
                                src={this.currentImgUrl}
                                className={innerClassName}
                                onLoad={this.onImgDoneLoading.bind(this)}
                                onError={this.onImgDoneLoading.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </CardMedia>
        );
    }

    actionButtons() {
        if (
            !this.props.deviceStatus.state ||
            !this.props.deviceStatus.isOnline
        ) {
            return <CardActions />;
        }

        const jobState = this.props.deviceStatus.state;
        let firstBtnDisabled = true;
        let secondBtnDisabled = true;
        let firstBtnBgColor = "gray";
        let secondBtnBgColor = "gray";
        let firstBtnText = "Pause";
        let firstBtnIcon = <FontIcon className="icon-mui-pause" />;
        let firstBtnCmd = "pause";

        let secondBtnText = "Cancel";
        let secondBtnIcon = <FontIcon className="icon-mui-cancel" />;
        let secondBtnCmd = "cancel";

        if (jobState.flags.printing) {
            firstBtnDisabled = false;
            secondBtnDisabled = false;
            firstBtnBgColor = "orange";
            secondBtnBgColor = "red";
        }

        if (jobState.flags.paused) {
            firstBtnDisabled = false;
            secondBtnDisabled = false;
            firstBtnBgColor = "green";
            secondBtnBgColor = "red";
            firstBtnText = "Resume";
            firstBtnIcon = <FontIcon className="icon-mui-play_arrow" />;
            firstBtnCmd = "resume";
        }

        const dlgBtns = [
            <FlatButton
                label="No"
                primary={true}
                keyboardFocused={true}
                onClick={() => this.cancel()}
            />,
            <FlatButton
                label="Yes"
                primary={false}
                onClick={() => this.confirm()}
            />
        ];
        return (
            <CardActions className="card-actions">
                <Dialog
                    title="Are you sure?"
                    actions={dlgBtns}
                    modal={false}
                    open={Boolean(this.state.cmdToConfirm)}
                    onRequestClose={() => this.cancel()}
                >
                    Do you really want to {this.state.cmdToConfirm} current
                    print job?
                </Dialog>
                <RaisedButton
                    disabled={firstBtnDisabled}
                    backgroundColor={firstBtnBgColor}
                    label={firstBtnText}
                    icon={firstBtnIcon}
                    onClick={() => this.showDialog(firstBtnCmd)}
                />
                <RaisedButton
                    disabled={secondBtnDisabled}
                    backgroundColor={secondBtnBgColor}
                    label={secondBtnText}
                    icon={secondBtnIcon}
                    onClick={() => this.showDialog(secondBtnCmd)}
                />
                <span className="clear" />
            </CardActions>
        );
    }

    cardHeader() {
        let title = get(this.props.device, "name", "");
        let subtitle = "Disconnected";
        let statusHelpMsg = "Your OctoPrint is not powered on or connected to Internet. It was last seen: " + this.lastSeen() + ".";
        let subtitleColor = colors.red500;
        let progressBar = <div />;

        if (this.props.deviceStatus.isOnline) {
            subtitleColor = "";
            if (!get(this.props.deviceStatus, "state.flags.operational", false)) {
                statusHelpMsg = "Your printer is not connected to OctoPrint. Please check if it is powered on and correctly recognized by OctoPrint.";
                subtitle = "Offline";
            } else {
                statusHelpMsg = null;
                if (this.jobInProgress()) {
                    let pct = parseFloat(
                        get(this.props.deviceStatus, "progress.completion", "0") ||
                        "0"
                    );
                    if (has(this.props.deviceStatus, "progress.printTime") && has(this.props.deviceStatus, "progress.printTimeLeft")) {
                        const pTime = parseFloat(get(this.props.deviceStatus, "progress.printTime"));
                        const pLeft = parseFloat(get(this.props.deviceStatus, "progress.printTimeLeft"));
                        pct = pTime/(pTime + pLeft)*100.0;
                    }
                    subtitle =
                        pct.toFixed() +
                        "% - " +
                        get(this.props.deviceStatus, "job.file.name", "");
                    progressBar = (
                        <LinearProgress
                            mode="determinate"
                            className="header-progress"
                            value={pct}
                        />
                    );
                } else {
                    subtitle = "Online - Idle";
                    subtitleColor = colors.greenA700;
                }
            }
        }

        const subtitleStyles = { color: subtitleColor }
        if (statusHelpMsg) {
            subtitleStyles.maxWidth = '120px'
        }

        return (
            <CardText className="card-header">
                <Avatar src={AvatarDefault} size={40} />
                <div className="card-title">
                    <div className="title-text">{title}</div>
                    {this.props.deviceStatus.waiting || (<div
                        className="subtitle-text"
                        style={subtitleStyles}
                        onMouseOver={event => this.openDeviceStatusHelp(event, statusHelpMsg)}
                        onClick={event => this.openDeviceStatusHelp(event, statusHelpMsg)}
                    >
                        {subtitle}
                        {statusHelpMsg && <FontIcon
                            className='icon-mui-help_outline'
                            style={{ ...styles.miniIcon, color: subtitleColor }}
                        />}
                    </div>)}
                    <Popover
                        open={
                            this.state.statusHelpMsg !== null
                        }
                        anchorEl={this.state.anchorEl}
                        canAutoPosition={true}
                        onRequestClose={
                            this.closeDeviceStatusHelp
                        }
                    >
                        <div
                            style={{
                                padding: "8px",
                                maxWidth: "260px"
                            }}
                        >{this.state.statusHelpMsg}</div>
                    </Popover>
                    {progressBar}
                </div>
                <CardMenu device={this.props.device} />
            </CardText>
        );
    }

    render() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <Card className="device-card">
                    {this.cardHeader()}
                    {!this.props.device.subscription ? (
                        <NeedToSubscribe />
                    ) : (
                            <div>
                                <Tabs>
                                    <Tab
                                        icon={
                                            <FontIcon className="icon-mui-video-camera" />
                                        }
                                    >
                                        {this.webcamFeed(false)}
                                        <CardText className="card-text">
                                            {this.temperatureFeed()}
                                            {this.jobFeed()}
                                        </CardText>
                                    </Tab>
                                    <Tab
                                        icon={
                                            <FontIcon className="icon-mui-settings_remote" />
                                        }
                                    >
                                        {this.webcamFeed(true)}
                                        <CardText className="card-text">
                                            <DeviceControls
                                                device={this.props.device}
                                            />
                                        </CardText>
                                    </Tab>
                                </Tabs>
                            </div>
                        )}
                </Card>
            </div>
        );
    }

    /* End of methods to create React elements */

    /* methods that deal with sending commands to Octoprint */
    sendCmd(cmd) {
        this.props.sendMessageToDevice(
            JSON.stringify({ cmd }),
            this.props.device.id
        );
    }

    showDialog(action) {
        this.setState(
            update(this.state, {
                cmdToConfirm: {
                    $set: action
                }
            })
        );
    }

    cancel() {
        this.setState(
            update(this.state, {
                cmdToConfirm: {
                    $set: null
                }
            })
        );
    }

    confirm() {
        if (this.state.cmdToConfirm === null) {
            return;
        }

        this.sendCmd({
            job: this.state.cmdToConfirm
        });

        this.setState(
            update(this.state, {
                cmdToConfirm: {
                    $set: null
                }
            })
        );
    }

    /* end of methods that deal with sending commands to Octoprint */
}

const mapStateToProps = (state, ownProps) => {
    const deviceStatus = state.deviceStatus[ownProps.device.id] || {
        waiting: true,
    };

    return {
        deviceStatus,
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        subscribeToDevice: deviceId => {
            dispatch(subscribeToDevice(deviceId));
        },
        unsubscribeFromDevice: deviceId => {
            dispatch(unsubscribeFromDevice(deviceId));
        },
        sendMessageToDevice: (msg, deviceId) => {
            dispatch(sendMessageToDevice(msg, deviceId));
        }
    };
};
export const DeviceCard = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceCardComponent);
