import React from 'react';

import styles from '../common/styles';

export default class extends React.Component {

    render() {
        return (
          <div className='container'>
            <div className='row center-xs center-sm center-md center-lg'>
                <div className='logout-paper col-xs-8 col-sm-6 col-md-4 col-lg-4 center'>
                <p>You are unsubscribed from all email notifications.</p>
                <a style={styles.link} href='/app/preferences'>Change notification settings.</a>
                </div>
            </div>
          </div>
        );
    }
}
